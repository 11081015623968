/* eslint-disable jsx-a11y/anchor-is-valid */
import {apiGetListDashBoard, apiGetListDashBoardAll} from '@src/app/services/api/dashboardApi'
import {apiGetListSchedule} from '@src/app/services/api/scheduleApi'
import {addTime, formatDateScheduleApi, formatDateValidate} from '@src/app/services/helper'
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {ListsWidget5, TablesWidget10} from '../../../_metronic/partials/widgets'
import {DashboardMap} from './DashboardMap'
import DashboardTable from './DashboardTable'
import {DetailAdmin} from './DetailAdmin'
import {IDashBoard} from './models/DashBoardModel'

const DashboardWrapper: FC = () => {
  const toDay: any = addTime(new Date().toUTCString(), 0)

  const [listDashBoard, setListDashBoard] = useState<any>()
  const [listDashBoardToday, setListDashBoardToday] = useState<any>()
  // console.log('listDashBoard', listDashBoard)
  const [van, setVan] = useState<any>()

  const getDashBoard = async () => {
    // if (van?.vanId === 'ALL') {
    //   const res = await apiGetListHistoryAll(formatDateValidate(date))
    //   if (res) setListHistory(res)
    //   if (res) setListHistoryDropoff(res?.logs.filter((el: any) => el?.action === 'dropoff'))
    //   if (res) setListHistoryDiscrepencies(res?.discrepencies)
    // } else {
    //   const res = await apiGetListHistory(formatDateValidate(date), van?.id)
    //   if (res) setListHistory(res)
    //   if (res) setListHistoryDropoff(res?.logs.filter((el: any) => el?.action === 'dropoff'))
    //   if (res) setListHistoryDiscrepencies(res?.discrepencies)
    // }

    const schedules = await apiGetListSchedule(formatDateScheduleApi(toDay), van?.id)
    // const schedules = await apiGetListSchedule('2022-04-22', van?.id)
    // console.log('schedules', schedules)

    const res = await apiGetListDashBoardAll(formatDateValidate(toDay), van?.id)
    // const res = await apiGetListDashBoard('2022-04-22')
    if (res && schedules) setListDashBoard({...res, schedules})
  }

  const getDashBoardToday = async () => {
    const listDashBoardToDay = await apiGetListDashBoard(formatDateValidate(toDay))
    if (listDashBoardToDay) setListDashBoardToday(listDashBoardToDay)
  }

  const selectVan = (van: any) => {
    setVan(van)
  }

  useEffect(() => {
    if (van !== undefined) getDashBoard()
  }, [van])

  useEffect(() => {
    getDashBoardToday()
  }, [])

  return (
    <>
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-4'>
          <DetailAdmin listDashBoardToday={listDashBoardToday} />
        </div>
        <div className='col-xxl-8'>
          <DashboardMap listDashBoard={listDashBoard} />
        </div>
      </div>

      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <DashboardTable listDashBoard={listDashBoard} selectVan={(van: any) => selectVan(van)} />
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
