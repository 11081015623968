import React, {lazy, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

export function PrivateRoutes() {
  const Schedule = lazy(() => import('../pages/schedule/index'))
  const AdHoc = lazy(() => import('../pages/ad-hoc/index'))
  const Beneficiaries = lazy(() => import('../pages/beneficiaries/index'))
  const BreadProgram = lazy(() => import('../pages/bread-program/index'))
  const ContactManagement = lazy(() => import('../pages/contact-management/index'))
  const Donors = lazy(() => import('../pages/donors/index'))
  const NotificationEmail = lazy(() => import('../pages/notification-email/index'))
  const PickupsDeliveryHistory = lazy(() => import('../pages/pickups-delivery-history/index'))
  const Reports = lazy(() => import('../pages/reports/index'))
  const Van = lazy(() => import('../pages/van/index'))
  const System = lazy(() => import('../pages/system-user/index'))
  const ManageUser = lazy(() => import('../pages/manage-account/index'))
  const ForgotPassWord = lazy(() => import('../pages/manage-account/ForgotPassWord'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/schedule' component={Schedule} />
        <Route path='/ad-hoc' component={AdHoc} />
        <Route path='/beneficiaries' component={Beneficiaries} />
        <Route path='/bread-program' component={BreadProgram} />
        <Route path='/contact-management' component={ContactManagement} />
        <Route path='/donors' component={Donors} />
        <Route path='/notification-email' component={NotificationEmail} />
        <Route path='/pickups-delivery-history' component={PickupsDeliveryHistory} />
        <Route path='/reports' component={Reports} />
        <Route path='/van' component={Van} />
        <Route path='/system-user' component={System} />
        <Route path='/manage-user' component={ManageUser} />
        {/* <Route path='/user/change-password/:email/:code' component={ForgotPassWord} /> */}
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
