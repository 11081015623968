import client from '.'

export const apiGetListContact = () => {
  return client.get(`/contacts`).then(res => res.data);
}

export const apiAddNewContact = (data: string) => {
  return client.post('/contacts', data).then(res => res.data);
}

export const putApiEditContactApi = (data: any) => {
  return client.put('/contacts', { ...data }).then((res) => res.data);
};

export const apiDeleteItemContact = (id: string) => {
  return client.delete<any>(`/contacts/${id}`).then((res) => res.data);
};

export const apiDeleteDonorContact = (id: number) => {
  return client.delete<any>(`/donors/deleteContact/${id}`).then((res) => res.data);
};

export const apiDeleteDeliveryAddress = (id: number) => {
  return client.delete<any>(`/beneficiaries/deleteDeliveryAddress/${id}`).then((res) => res.data);
};

export const apiDeleteTargetGroup = (id: number) => {
  return client.delete<any>(`/beneficiaries/deleteTargetGroup/${id}`).then((res) => res.data);
};

export const apiDeleteItemContactBeneficiaries = (id: number) => {
  return client.delete<any>(`/beneficiaries/deleteContactType/${id}`).then((res) => res.data);
};

export const apiDeleteBeneficiariesFoodItem = (id: number) => {
  return client.delete<any>(`/beneficiaries/deletePreferFood/${id}`).then((res) => res.data);
};

export const apiDeleteBeneficiariesRQFoodItem = (id: number) => {
  return client.delete<any>(`/beneficiaries/deleteRequestFood/${id}`).then((res) => res.data);
};

export const apiDeleteDonorFoodItem = (id: number) => {
  return client.delete<any>(`/donors/deleteDonorFood/${id}`).then((res) => res.data);
};



