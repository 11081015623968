import produce from 'immer';
import { clone } from '../../../services/helper';
import { ISystemType } from '../models/SystemModel';
import { EManageSystemUserType } from './ManagaSystemUserAction';
import {IFoodClass, IFoodType, IUser} from '../models/UserModel'
// import { EManageSurveyType } from './ManagaSurveyAction';
// import { ICustomerSurveyType } from '../model/CustomerSurveyModel';
interface IState {
  ListUser: IUser[]
  ListSystem: ISystemType[]
  FoodClassList: IFoodClass[]
  TypeClassList: IFoodType[]
  ListConfigs: {}
}

export const defaultState: IState = {
  ListSystem: [],
  ListUser: [],
  FoodClassList: [],
  TypeClassList: [],
  ListConfigs: {}
}

const ManagaSystemUserReducer = (state = defaultState, action: any) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case EManageSystemUserType.SAVE_USER: {
        draft.ListUser = action.payload
        break
      }

      case EManageSystemUserType.ADD_LIST_USER: {
        const newUser = [
          ...[action.payload],
          ...state.ListUser,
        ];
        draft.ListUser = newUser;
        break;
      }

      case EManageSystemUserType.EDIT_USER_RD: {
        const index = state.ListUser.findIndex((pp) => pp.id === action.payload.id)
        const newArr = clone(state.ListUser)
        if (index !== undefined && index !== -1) {
          newArr.splice(index, 1, action.payload)
        }
        draft.ListUser = newArr
        break
      }

      case EManageSystemUserType.DELETE_ITEM_USER_RD: {
        const newUser = state.ListUser.filter(
          (pp) => pp.id !== action.payload,
        );
        draft.ListUser = newUser;
        break;
      }

      case EManageSystemUserType.FILTER_SUCCESS_USER: {
        draft.ListUser = action.payload?.data
        break
      }

      case EManageSystemUserType.SORT_SUCCESS_USER: {
        draft.ListUser = action.payload?.data
        break
      }

      // ======== SYSTEM TYPE =========== //
      case EManageSystemUserType.SAVE_SYSTEM_RD: {
        draft.ListSystem = action.payload;
        break;
      }

      case EManageSystemUserType.SAVE_CONFIGS: {
        draft.ListConfigs = action.payload;
        break;
      }

      case EManageSystemUserType.ADD_LIST_SYSTEM: {
        const newSystem = [
          ...[action.payload],
          ...state.ListSystem,
        ];
        draft.ListSystem = newSystem;
        break;
      }

      case EManageSystemUserType.EDIT_SYSTEM_RD: {
        const index = state.ListSystem.findIndex((pp) => pp.id === action.payload.id)
        const newArr = clone(state.ListSystem)
        if (index !== undefined && index !== -1) {
          newArr.splice(index, 1, action.payload)
        }
        draft.ListSystem = newArr
        break
      }

      case EManageSystemUserType.DELETE_ITEM: {
        const newSystem = state.ListSystem.filter(
          (pp) => pp.id !== action.payload,
        );
        draft.ListSystem = newSystem;
        break;
      }



      // ================= FOOD CLASS ==================== //
      case EManageSystemUserType.SAVE_FOODCALSS: {
        draft.FoodClassList = action.payload
        break
      }

      case EManageSystemUserType.ADD_LIST_FOODCLASS: {
        const newFoodClass = [...[action.payload], ...state.FoodClassList]
        draft.FoodClassList = newFoodClass
        break
      }

      case EManageSystemUserType.EDIT_ITEM_FOODCLASS_RD: {
        const index = state.FoodClassList.findIndex((pp) => pp.id === action.payload.id)
        const newArr = clone(state.FoodClassList)
        if (index !== undefined && index !== -1) {
          newArr.splice(index, 1, action.payload)
        }
        draft.FoodClassList = newArr
        break
      }

      case EManageSystemUserType.DELETE_ITEM_FOODCLASS_RD: {
        const newFoodClass = state.FoodClassList.filter((pp) => pp.id !== action.payload)
        draft.FoodClassList = newFoodClass
        break
      }

      case EManageSystemUserType.FILTER_SUCCESS: {
        draft.FoodClassList = action.payload?.data
        break
      }

      case EManageSystemUserType.SORT_SUCCESS_FOOD_CLASS: {
        draft.FoodClassList = action.payload?.data
        break
      }

      // ================= FOOD TYPE ==================== //
      case EManageSystemUserType.SAVE_FOODTYPE: {
        draft.TypeClassList = action.payload
        break
      }

      case EManageSystemUserType.ADD_LIST_FOODTYPE: {
        const newFoodType = [...[action.payload], ...state.TypeClassList]
        draft.TypeClassList = newFoodType
        break
      }

      case EManageSystemUserType.EDIT_ITEM_FOODTYPE_RD: {
        const index = state.TypeClassList.findIndex((pp) => pp.id === action.payload.id)
        const newArr = clone(state.TypeClassList)
        if (index !== undefined && index !== -1) {
          newArr.splice(index, 1, action.payload)
        }
        draft.TypeClassList = newArr
        break
      }

      case EManageSystemUserType.DELETE_ITEM_FOODTYPE_RD: {
        const newTypeClass = state.TypeClassList.filter((pp) => pp.id !== action.payload)
        draft.TypeClassList = newTypeClass
        break
      }

      case EManageSystemUserType.FILTER_SUCCESS_FOODTYPE: {
        draft.TypeClassList = action.payload
        break
      }

      case EManageSystemUserType.SORT_SUCCESS_FOOD_TYPE: {
        draft.TypeClassList = action.payload?.data
        break
      }

      default:
        break
    }
  })
}
export default ManagaSystemUserReducer
