import {history} from '@src/app/App'
import {API_URL} from '@src/app/constants/constant'
import {alertCode401} from '@src/app/services/helper'
import axios from 'axios'

const client = axios.create({
  baseURL: API_URL,
  timeout: 100000,
})

client.interceptors.request.use(
    async config => {
        if (config.method === 'get') {
            const currentTime = new Date().getTime();
            const oldUrl: any = config.url
            let newUrl = config.url;
            if (oldUrl.includes('?')) {
                newUrl = `${oldUrl}&time=${currentTime}`
            } else {
                newUrl = `${oldUrl}?time=${currentTime}`
            }
            config.url = newUrl;
        }
        const accessToken = localStorage.getItem('accessToken')
        const cloneConfig = { ...config };
        if (accessToken) {
            cloneConfig.headers.Authorization = `${accessToken}`;
        }

    return cloneConfig
  },
  (error) => Promise.reject(error)
)

client.interceptors.response.use(
  (response) => response,
  (error) => responseErrorHandler(error)
)

const responseErrorHandler = async (error: any) => {
  if (error.response.status === 401) {
    alertCode401('Your session has ended, please login again !')
    setTimeout(() => {
      history.push('/logout')
    }, 1000)
  }
  return Promise.reject(error.response.data)
}

export default client
