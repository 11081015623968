import {IUser} from '@pages/system-user/models/UserModel'
import {AxiosResponse} from 'axios'
import {call, put, takeEvery, takeLatest} from 'redux-saga/effects'
import {apiGetListPost, apiChangePassword} from '../../../services/api/authApi'
import {alertError, alertSuccess} from '../../../services/helper'
import {EAuthType} from './AuthActions'

function* login() {
  try {
    const res: AxiosResponse<any> = yield call(apiGetListPost)

    // yield put({
    //     type: EAuthType.LOIGN_SUCCESS,
    //     payload: {}
    // })
  } catch (error) {}
}

function* editPassWord({payload}: any) {
  try {
    const res: IUser = yield call(apiChangePassword, payload.user)
    payload.onSuccess()
    alertSuccess('Successfully Edit PassWord ')
  } catch (error) {
    payload.onError()
    alertError(error.message)
  }
}

// function* validateResetPassWatcher({ payload }: any) {
//     try {
//         const res: AxiosResponse<any> = yield call(validateResetPassword, payload.email, payload.code);
//         payload.onSuccess(res)
//     } catch (error: any) {
//         payload.onError(error)

//     }
// }

// function* resetPassWatcher({ payload }: any) {
//     try {
//         const res: AxiosResponse<any> = yield call(resetPassword, payload.email, payload.newPassword, payload.confirmPassword, payload.isReset);
//         payload.onSuccess()
//     } catch (error: any) {
//         payload.onError(error)

//     }
// }

// function* updateProfile({ payload }: any) {
//      if (payload.file) {
//             const avarlUrl: Array<AxiosResponse<any>> =
//               yield call(
//                 //   apiUploadFile,
//                   payload.file,
//                   payload.file.name
//                 )

//                 payload.user.avatar = avarlUrl;
//           }
//     try {
//         const data: AxiosResponse<any> = yield call(apiUpdateProfile,  payload.user);
//         payload.onSuccess();
//         if (data) {
//             yield put({
//               type: EAuthType.EDIT_Profile,
//               payload: data,
//             });
//           }
//         alertSuccess('Cập nhật thành công')
//     } catch (error) {
//         payload.onError()
//         alertError("Cập nhật không thành công")
//     }
// }

export default function* authSaga() {
  yield takeEvery(EAuthType.LOGIN, login)
  yield takeLatest(EAuthType.EDIT_PASS, editPassWord)

  // yield takeLatest(EAuthType.VALIDATE_RESET_PASSWORD_SAGA, validateResetPassWatcher);
  // yield takeLatest(EAuthType.RESET_PASSWORD_SAGA, resetPassWatcher);
  // yield takeLatest(EAuthType.UPDATE_PROFILE, updateProfile);
}
