import {INotificationItem} from '../models/NotificationModel'

export enum EManageNotificationsType {
  GET_LIST_NOTIFICATIONS = '[manage Notification] GET_LIST_NOTIFICATIONS',
  DELETE_NOTIFICATION = '[manage Notification] DELETE_NOTIFICATION',
}

export const getListNotificationsAction = (payload: {
  page: number
  size: number
  onSuccess: (listNotifications: INotificationItem[], totalPage: number) => void
  onError: () => void
}) => ({
  type: EManageNotificationsType.GET_LIST_NOTIFICATIONS,
  payload,
})

export const deleteNotificationAction = (payload: {
  notificationId: string
  onSuccess: () => void
  onError: () => void
}) => ({
  type: EManageNotificationsType.DELETE_NOTIFICATION,
  payload,
})
