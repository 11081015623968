import {UserModel} from '@modules/auth/models/UserModel'
import {updateChangePassWordAction} from '@modules/auth/redux/AuthActions'
import {queryParamToObject} from '@src/app/services/helper'
import {useSelector} from '@src/setup/redux/RootReducer'
import {useFormik} from 'formik'
import {Fragment, useState} from 'react'
import {shallowEqual, useDispatch} from 'react-redux'
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom'
import * as Yup from 'yup'
// import { useSelector } from "../../../../setup/redux/RootReducer";
// import { UserModel } from "../../../modules/auth/models/UserModel";

type Props = {}
const profileDetailsSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('This field cannot be left blank.')
    .trim('There must be no spaces.')
    .strict()
    .min(3, 'Minimum must be more than 3 characters.')
    .max(30, 'Up to 30 characters.'),
  newPassword: Yup.string()
    .required('This field cannot be left blank.')
    .trim('There must be no spaces.')
    .strict()
    .min(3, 'Minimum must be more than 3 characters.')
    .max(30, 'Up to 30 characters.'),
  confirmPassword: Yup.string()
    .required('This field cannot be left blank.')
    .trim('There must be no spaces.')
    .strict()
    .oneOf([Yup.ref('newPassword')], 'Password incorrect !'),
})

const SchemaWithCode = Yup.object().shape({
  newPassword: Yup.string()
    .required('This field cannot be left blank.')
    .trim('There must be no spaces.')
    .strict()
    .min(3, 'Minimum must be more than 3 characters.')
    .max(30, 'Up to 30 characters.'),
  confirmPassword: Yup.string()
    .required('This field cannot be left blank.')
    .trim('There must be no spaces.')
    .strict()
    .oneOf([Yup.ref('newPassword')], 'Password incorrect !'),
})

const Changepassword: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const account = useSelector(({auth}) => auth.user, shallowEqual) as UserModel
  const {email, code} = queryParamToObject<{email: string; code: any}>(window.location.search)

  const initialValues = {
    email: `${code !== undefined ? email : account?.email}` ?? '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    code: '',
  }
  const validateSchema = code !== undefined ? SchemaWithCode : profileDetailsSchema
  const formik = useFormik({
    initialValues,
    validationSchema: validateSchema,
    onSubmit: (values: any) => {
      setLoading(true)
      const userData: any = {
        email: `${code !== undefined ? email : values.email}`,
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
        code: code,
      }

      dispatch(
        updateChangePassWordAction({
          user: userData,
          onSuccess: () => {
            setLoading(false)
            history.push('auth/login')
          },
          onError: () => {
            setLoading(false)
          },
        })
      )
    },
  })

  return (
    <>
      <div className={`card`} style={{height: '100%'}}>
        <h3 className='card-title align-items-start flex-column mt-10 ms-10'>
          <span className='card-label fw-bolder fs-3 mt-10'>CHANGE PASSWORD</span>
        </h3>
        <div className='row mb-6 justify-content-center mt-10'>
          <div className='col-lg-8'>
            <div id='kt_account_profile_details' className='collapse show'>
              <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className='card-body border-bottom p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>Email</label>
                    <div className='col-lg-9 fv-row'>
                      <input
                        readOnly
                        type='email'
                        className={`form-control form-control-lg form-control-solid ${
                          formik.touched.email && formik.errors.email ? 'border-danger' : ''
                        }`}
                        {...formik.getFieldProps('email')}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container mt-2'>
                          <div className='fv-help-block text-danger'>{formik.errors.email}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  {code === undefined ? (
                    <div className='row mb-6'>
                      <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                        Old Password
                      </label>
                      <div className='col-lg-9 fv-row'>
                        <input
                          type='password'
                          className={`form-control form-control-lg form-control-solid ${
                            formik.touched.oldPassword && formik.errors.oldPassword
                              ? 'border-danger'
                              : ''
                          }`}
                          {...formik.getFieldProps('oldPassword')}
                        />
                        {formik.touched.oldPassword && formik.errors.oldPassword && (
                          <div className='fv-plugins-message-container mt-2'>
                            <div className='fv-help-block text-danger'>
                              {formik.errors.oldPassword}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}

                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      New Password
                    </label>
                    <div className='col-lg-9 fv-row'>
                      <input
                        type='password'
                        className={`form-control form-control-lg form-control-solid ${
                          formik.touched.newPassword && formik.errors.newPassword
                            ? 'border-danger'
                            : ''
                        }`}
                        {...formik.getFieldProps('newPassword')}
                      />
                      {formik.touched.newPassword && formik.errors.newPassword && (
                        <div className='fv-plugins-message-container mt-2'>
                          <div className='fv-help-block text-danger'>
                            {formik.errors.newPassword}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                      Confirm Password
                    </label>
                    <div className='col-lg-9 fv-row'>
                      <input
                        type='password'
                        className={`form-control form-control-lg form-control-solid ${
                          formik.touched.confirmPassword && formik.errors.confirmPassword
                            ? 'border-danger'
                            : ''
                        }`}
                        {...formik.getFieldProps('confirmPassword')}
                      />
                      {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className='fv-plugins-message-container mt-2'>
                          <div className='fv-help-block text-danger'>
                            {formik.errors.confirmPassword}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='card-footer border-0 d-sm-flex justify-content-end p-sm-0 py-sm-6 px-sm-9'>
                  <button type='submit' className='btn btn-success p-3 mx-sm-4' disabled={loading}>
                    {loading ? (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please Wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : (
                      <Fragment>
                        <i className='far fa-edit'></i>CHANGE
                      </Fragment>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Changepassword
