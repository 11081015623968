/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG} from '@metronic/helpers'
import {Dropdown1} from '@metronic/partials'
import {formatDateDashboard} from '@src/app/services/helper'
import {useSelector} from '@src/setup/redux/RootReducer'
import React from 'react'
import {IDashBoard} from './models/DashBoardModel'

type Props = {
  listDashBoardToday: IDashBoard
}

const DetailAdmin: React.FC<Props> = ({listDashBoardToday}) => {
  const account = useSelector((state) => state.auth.user)
  const toDay = new Date()

  return (
    <div className={`card card-xl-stretch mb-xl-8`}>
      <div className={`card-header border-0 py-5`} style={{height: 150}}>
        <div>
          <h3 className='card-title fw-bolder text-dark'>
            {' '}
            WELCOME {account?.firstName} {account?.lastName}
          </h3>
          <span className='fw-bolder text-dark'>{formatDateDashboard(toDay)}</span>
        </div>
      </div>

      <div className='card-body p-0'>
        <div className={`mixed-widget-2-chart card-rounded-bottom back-ground-green`}></div>
        <div className='card-p mt-n20 position-relative'>
          <div className='row g-0'>
            <div className='col back-ground-blue px-6 py-8 rounded-2 me-7 mb-7'>
              <h3 className='card-title fw-bolder text-white'>
                {listDashBoardToday?.deliveriesToday}
              </h3>
              <a href='#' className='text-white fw-bold fs-6'>
                Deliveries Today
              </a>
            </div>
            <div className='col back-ground-blue px-6 py-8 rounded-2 me-7 mb-7'>
              <h3 className='card-title fw-bolder text-white'>{listDashBoardToday?.van}</h3>
              <a href='#' className='text-white fw-bold fs-6'>
                Vans In Operation
              </a>
            </div>
          </div>
          <div className='row g-0'>
            <div className='col back-ground-green px-6 py-8 rounded-2 me-7 mb-7'>
              <h3 className='card-title fw-bolder text-white'>{listDashBoardToday?.donorsToday}</h3>
              <a href='#' className='text-white fw-bold fs-6'>
                Donors Today
              </a>
            </div>
            <div className='col back-ground-green px-6 py-8 rounded-2 me-7 mb-7'>
              <h3 className='card-title fw-bolder text-white'>{listDashBoardToday?.adhocOrder}</h3>
              <a href='#' className='text-white fw-bold fs-6'>
                Ad Hoc Orders
              </a>
            </div>
          </div>
          <div className='row g-0'>
            <div className='col back-ground-blue px-6 py-8 rounded-2 me-7 mb-7'>
              <h3 className='card-title fw-bolder text-white'>
                {listDashBoardToday?.adhocRecurring}
              </h3>
              <a href='#' className='text-white fw-bold fs-6'>
                Recurring Ad Hoc Pieces
              </a>
            </div>
            <div className='col back-ground-blue px-6 py-8 rounded-2 me-7 mb-7'>
              <h3 className='card-title fw-bolder text-white'>
                {listDashBoardToday?.driverWorking}
              </h3>
              <a href='#' className='text-white fw-bold fs-6'>
                Drivers Working Today
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {DetailAdmin}
