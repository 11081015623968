import {call, put, takeLatest} from 'redux-saga/effects'
import {alertError, alertSuccess} from '../../../services/helper'
import {
  apiAddNewVan,
  apiGetListVan,
  putApiEditVanApi,
  apiDeleteItemVan,
  apiGetListFilterVan,
} from '@src/app/services/api/vanApi'
import {IListResult} from '@src/app/common/CommonModel'
import {
  addNewNoteScheduleActionSaga,
  addNewScheduleActionSaga,
  EManageScheduleType,
  getListNoteAction,
  getScheduleAction,
  OptimseScheduleActionSaga,
  saveNoteScheduleAction,
  saveScheduleAction,
} from './ManagaScheduleAction'
import {
  apiAddNewVNoteSchedule,
  apiAddNewVSchedule,
  apiDeleteItemSchedule,
  apiDeleteItemScheduleNote,
  apiGetListNoteSchedule,
  apiGetListSchedule,
  apiOptimiseSchedule,
  apiReAssigned,
} from '@src/app/services/api/scheduleApi'
import {ISchedule} from '../models/ScheduleModel'
import {getAllDonorScheduleAction} from '@pages/donors/redux/ManagaDonorsAction'

function* getListScheduleWatcher({payload}: any) {
  try {
    const res: ISchedule[] = yield call(apiGetListSchedule, payload.date, payload.vanId)
    if (res) {
      yield put(saveScheduleAction(res))
    }
  } catch (error) {}
}

function* addNewScheduleWatcher({payload}: ReturnType<typeof addNewScheduleActionSaga>) {
  try {
    for (let i = 0; i < payload.valueSchedule.length; i++) {
      const res: ISchedule = yield call(apiAddNewVSchedule, payload.valueSchedule[i])
      yield put(getScheduleAction(res))
    }

    payload.onSuccess()
    // alertSuccess('Save successfully !')
  } catch (error: any) {
    payload.onError()
    alertError(error ?? 'Add New Failed !')
  }
}

function* deleteScheduleItemTypeWatcher({payload}: any) {
  try {
    const res: ISchedule[] = yield call(apiDeleteItemSchedule, payload.id)
    yield put({
      type: EManageScheduleType.DELETE_ITEM_SCHEDULE_RD,
      payload: payload.id,
    })
    alertSuccess('Delete successful')
    payload.onSuccess(res)
    // yield put(getAllDonorScheduleAction())
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* reAssignedWatcher({payload}: any) {
  try {
    const res: any[] = yield call(apiReAssigned, payload.value)
    payload.onSuccess(payload.value)
    alertSuccess('Re-Assigned successfully !')
  } catch (error: any) {
    alertError('Re-Assigned Failed !')
  }
}

function* getListNoteScheduleWatcher({payload}: any) {
  try {
    const res: ISchedule[] = yield call(apiGetListNoteSchedule, payload.type, payload.subjectId)
    if (res) {
      yield put(saveNoteScheduleAction(res))
    }
  } catch (error) {}
}

function* addNewNoteScheduleWatcher({payload}: ReturnType<typeof addNewNoteScheduleActionSaga>) {
  // console.log('payloadSaga', payload.valueSchedule)
  try {
    const res: ISchedule = yield call(apiAddNewVNoteSchedule, payload.value)
    // yield put(getListNoteAction(res))
    payload.onSuccess()
    alertSuccess('Save successfully !')
  } catch (error: any) {
    payload.onError()
    alertError(error ?? 'Add New Failed !')
  }
}

function* deleteNoteScheduleItemTypeWatcher({payload}: any) {
  try {
    const res: any[] = yield call(apiDeleteItemScheduleNote, payload.id)
    yield put({
      type: EManageScheduleType.DELETE_ITEM_SCHEDULE_NOTE,
      payload: payload.id,
    })
    alertSuccess('Delete successful')
    payload.onSuccess()
    // yield put(getAllDonorScheduleAction())
  } catch (error: any) {
    payload.onError()
    alertError('Delete fail')
  }
}

function* optimiseScheduleItemTypeWatcher({payload}: ReturnType<typeof OptimseScheduleActionSaga>) {
  try {
    const res: ISchedule = yield call(apiOptimiseSchedule, payload.schedule)
    payload.onSuccess()
  } catch (error: any) {
    payload.onError()
    alertError(error ?? 'Add New Failed !')
  }
}

export default function* manageScheduleSaga() {
  yield takeLatest(EManageScheduleType.GET_LIST_SCHEDULE, getListScheduleWatcher)
  yield takeLatest(EManageScheduleType.ADD_NEW_SCHEDULE, addNewScheduleWatcher)
  yield takeLatest(EManageScheduleType.DELETE_ITEM_SCHEDULE, deleteScheduleItemTypeWatcher)
  yield takeLatest(EManageScheduleType.OPTIMISE_SCHEDULE, optimiseScheduleItemTypeWatcher)

  yield takeLatest(EManageScheduleType.RE_ASSIGNED, reAssignedWatcher)
  yield takeLatest(EManageScheduleType.GET_LIST_NOTE_SCHEDULE, getListNoteScheduleWatcher)
  yield takeLatest(EManageScheduleType.ADD_NEW_NOTE_SCHEDULE, addNewNoteScheduleWatcher)
  yield takeLatest(EManageScheduleType.DELETE_ITEM_SCHEDULE_Note, deleteNoteScheduleItemTypeWatcher)
}
