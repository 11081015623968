import { IBeneficiaries } from '@pages/beneficiaries/models/Beneficiaries'
import client from '.'

export const apiGetListBeneficiaries = () => {
  return client.get(`/beneficiaries`).then((res) => res.data)
}

export const apiGetListAllBeneficiaries = (date: string, donorId?: any) => {
  return client.get(`/beneficiaries?date=${date}&donorId=${donorId||''}`).then((res) => res.data)
}

export const apiAddNewBeneficiaries = (data: IBeneficiaries) => {
  return client.post('/beneficiaries', data).then((res) => res.data)
}

export const putApiEditBeneficiariesApi = (data: any) => {
  return client.put('/beneficiaries', { ...data }).then((res) => res.data)
}

export const apiDeleteItemBeneficiaries = (id: number) => {
  return client.delete<any>(`/beneficiaries/${id}`).then((res) => res.data)
}

export const apiGetItemBeneficiaries = (id: any) => {
  return client.get<any>(`/beneficiaries/${id}`).then((res) => res.data)
}

export const apiUpdatePreferredFoodType = (data: any) => {
  return client.put('/beneficiaries/updatePreferFood', data).then((res) => res.data)
}
