import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { AuthPage } from '../modules/auth/AuthPage'
import Changepassword from '../pages/manage-account/Changepassword'

export function PublicRoutes() {
  return (
    <Switch>
      <Route path='/manage-user/change-password' component={Changepassword} />
      <Route path='/auth' component={AuthPage} />
      <Redirect to='/auth' />
    </Switch>
  )
}
