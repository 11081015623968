export enum EReportType {
    REPORTS_ACTION = 'REPORTS_ACTION',

    ARCHIVING_ACTION = 'ARCHIVING_ACTION',
}

export const reportsAction = (payload: any, cbSuccess?: () => void, cbFailure?: () => void) => ({
    type: EReportType.REPORTS_ACTION,
    payload,
    cbSuccess,
    cbFailure
})

export const archivingAction = (cb?: () => void) => ({
    type: EReportType.ARCHIVING_ACTION,
    cb
})