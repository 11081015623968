import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import * as Yup from 'yup'
import {queryParamToObject, sleep} from '../../../services/helper'
import {resetPasswordSaga, validateResetPasswordSaga} from '../redux/AuthActions'

const initialValues = {
  newPassword: '',
  confirmPassword: '',
}

const resetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('New password is required'),
  confirmPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Confirm password is required')
    .oneOf([Yup.ref('newPassword'), null], 'New password and confirm password must match'),
})

export default function ResetPassword() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [state, setState] = useState({
    hasErrors: 'Loading',
    loading: true,
  })

  useEffect(() => {
    _getJWT(window.location.search)
  }, [window.location.search])

  const _getJWT = (pathname: string) => {
    if (!pathname) return
    const objReset = queryParamToObject<{email: string; token: string}>(pathname)

    dispatch(
      validateResetPasswordSaga({
        email: objReset.email,
        code: objReset.token,
        onSuccess: (success: any) => {
          localStorage.setItem('accessToken', success.jwtToken)
          setState((state) => ({
            ...state,
            hasErrors: '',
          }))
        },
        onError: (error: any) => {
          setState((state) => ({
            ...state,
            hasErrors: error,
          }))
        },
      })
    )
  }

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      const objReset = queryParamToObject<{email: string; token: string}>(window.location.search)
      dispatch(
        resetPasswordSaga({
          email: objReset.email,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
          isReset: true,
          onSuccess: async () => {
            setState((state) => ({
              ...state,
              hasErrors: 'Bạn đã khôi phục mật khẩu thành công.',
            }))
            await sleep(2000)
            history.replace('auth/login')
          },
          onError: (error: any) => {
            setState((state) => ({
              ...state,
              hasErrors: error,
            }))
          },
        })
      )
    },
  })

  return (
    <>
      {state.hasErrors ? (
        <>
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{state.hasErrors}</div>
          </div>
          <Link to='/auth/forgot-password'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
            >
              Back
            </button>
          </Link>
        </>
      ) : (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>Reset Password</h1>

            <div className='text-gray-400 fw-bold fs-4'>Enter your new password to reset.</div>
          </div>

          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>New password</label>
            <input
              type='password'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('newPassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                },
                {
                  'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                }
              )}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span style={{color: '#f1416c'}}>{formik.errors.newPassword}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm password</label>
            <input
              type='password'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('confirmPassword')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                },
                {
                  'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                }
              )}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span style={{color: '#f1416c'}}>{formik.errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>

          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary fw-bolder me-4'
            >
              <span className='indicator-label'>Submit</span>
              {state.loading && (
                <span className='indicator-progress'>
                  Please Wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-lg btn-light-primary fw-bolder'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      )}
    </>
  )
}
