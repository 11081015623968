/* eslint-disable react/jsx-no-target-blank */
// import { history } from "@";
import { useSelector } from '@src/setup/redux/RootReducer'
import clsx from 'clsx'
import React, { Fragment, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useHistory } from 'react-router-dom'
import useModal from '../hooks/useModal'
import PopUp from '../PopUpSignOut/PopUp'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import MenuList, { IMenuItem, IPermission, PermissionAction } from './MenuList'
import { setBlockNav } from '@src/app/services/helper'
import ModalConfirmExit from '@src/app/common/Modal/ModalConfirmExit'
import { VERSION } from '@src/app/constants/constant'
export function AsideMenuMain() {
  const userInfo = useSelector((state) => state.auth.user)
  const history = useHistory()
  const [menuList, setMenuList] = useState<IMenuItem[]>([])
  const { stateModal, toggleModal, offModal } = useModal()
  const [modalConfirmExit, setModalConfirmExit] = useState<any>({ show: false })

  // useEffect(() => {
  //   menuList.map()
  // })
  const showBlockMenuModal = (menu: any) => () => {
    setModalConfirmExit({ show: true, menu })
  }
  const confirmNavigate = (menu: any) => {
    history.push(menu.to)
    setBlockNav(false)
    setModalConfirmExit({ show: false })
  }
  useEffect(() => {
    history.listen(() => {
      setBlockNav(false)
    })
  }, [])
  useEffect(() => {
    if (userInfo) {
      let permissions = [] as IPermission[]

      const viewPermissions = permissions
        .filter((p) => (p.action | PermissionAction.view) === p.action)
        .map((e) => e.permission)
      let newMenu: IMenuItem[] = []
      for (let menu of MenuList) {
        if (viewPermissions.includes(menu.id)) {
          newMenu.push(menu)
        } else {
          const new_childs = (menu.childs || []).filter((child) =>
            viewPermissions.includes(child.id)
          )
          if (new_childs.length) {
            menu.childs = new_childs
            newMenu.push(menu)
          }
        }
      }
      setMenuList(newMenu)
    }
  }, [userInfo])

  useEffect(() => {
    if (menuList.length) {
      let white_list_path: string[] = []
      for (let menu of menuList) {
        white_list_path.push(menu.to)
        for (let child of menu.childs || []) {
          white_list_path.push(child.to)
        }
      }

      const isAccessPath = white_list_path.filter(
        (path) => window.location.pathname.indexOf(path) !== -1
      ).length

      if (isAccessPath === 0) {
        history.push('/dashboard')
      }
    }
  }, [menuList, window.location.pathname])

  const MenuNoChild = ({ menu }: { menu: IMenuItem }) => {
    return (
      <div className='mt-3'>
        <AsideMenuItem
          to={menu.to}
          title={menu.title}
          fontIcon={menu.fontIcon}
          hasBullet={menu.hasBullet}
        />
      </div>
    )
  }
  const MenuChild = ({ menu }: { menu: IMenuItem }) => {
    return (
      <>
        <AsideMenuItemWithSub to={menu.to} title={menu.title} fontIcon={menu.fontIcon}>
          {menu.childs?.map((child, index) => {
            return (
              <div key={index} style={{ position: 'relative' }}>
                <div className='sidebar-dirty-overlay' onClick={showBlockMenuModal(child)} />
                <AsideMenuItem to={child.to} title={child.title} fontIcon={child.fontIcon} />
              </div>
            )
          })}
        </AsideMenuItemWithSub>
      </>
    )
  }

  const showPopUpSignOut = () => {
    console.log('object..... :>> ')
  }

  return (
    <>
      {MenuList.map((menu, index) => {
        return menu.childs?.length ? (
          <Fragment key={index}>
            <MenuChild menu={menu} />
          </Fragment>
        ) : (
            <div key={index} style={{ position: 'relative' }}>
              <div className='sidebar-dirty-overlay' onClick={showBlockMenuModal(menu)} />
              <MenuNoChild menu={menu} />
            </div>
          )
      })}

      <div className={clsx('menu-item mb-0', 'menu-accordion')} data-kt-menu-trigger='click'>
        <span
          className='menu-link sign-out'
          style={{ paddingLeft: 25, paddingRight: 25, cursor: 'pointer' }}
          onClick={toggleModal(true, 'cancel', {})}
        >
          <i className='bi bi-box-arrow-left fs-3 me-3'></i>
          <span className='menu-title'>Sign Out</span>
        </span>
      </div>

      <div className={clsx('menu-item mb-0', 'menu-accordion','text-center','mt-3')}>
        <span
          className='text-white'
        >
          <span className='menu-title'>Version: {VERSION}</span>
        </span>
      </div>
      <Modal
        dialogClassName='modal-dialog-centered mw-1000px h-auto child-modal'
        aria-hidden='true'
        show={stateModal.open}
        onHide={offModal}
      >
        <div className='modal-content'>
          <PopUp onClose={offModal} mode={stateModal.typeModal} data={stateModal.data} />
        </div>
      </Modal>

      <Modal
        dialogClassName='modal-dialog-centered mw-1000px h-auto parent-modal'
        aria-hidden='true'
        show={modalConfirmExit.show}
      >
        <div className='modal-content'>
          <ModalConfirmExit
            confirmNavigate={() => confirmNavigate(modalConfirmExit.menu)}
            onClose={() => {
              setModalConfirmExit({ ...modalConfirmExit, show: false })
            }}
          />
        </div>
      </Modal>
    </>
  )
}
