/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG, toAbsoluteUrl} from '@metronic/helpers'
import {IVan} from '@pages/van/models/VanModel'
import {getVanAction} from '@pages/van/redux/ManagaVanAction'
import {apiGetListDashBoard} from '@src/app/services/api/dashboardApi'
import {addTime, formatDate, formatDateValidate} from '@src/app/services/helper'
import {useSelector} from '@src/setup/redux/RootReducer'
import React, {useEffect, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import DashboardTableItem from './components/DashboardTableItem'
import {IDashBoard, ILogs} from './models/DashBoardModel'

type Props = {
  listDashBoard: IDashBoard
  selectVan: any
}

const VanNav = ({ListVan, selectedVan, onSelect}: any) => {
  return ListVan
    ? ListVan.filter((van: any) => van?.status === 'Active').map((van: any, index: number) => {
        return (
          <span
            className={`css-van ${selectedVan?.id == van?.id ? 'van-active' : ''}`}
            style={{cursor: 'pointer'}}
            onClick={() => onSelect(van)}
          >
            <div className='van-item'>{van.vanId}</div>
          </span>
        )
      })
    : null
}

const DashboardTable: React.FC<Props> = ({listDashBoard, selectVan}) => {
  const ListVan = useSelector((state) => state.Van.ListVan).filter(x=>x.status=="Active")
  const dispatch = useDispatch()

  const [selectedVan, setSelectedVan] = useState<any>()

  const onSelectVan = (van: IVan) => {
    setSelectedVan(van)
  }

  useEffect(() => {
    if (ListVan.length > 0) setSelectedVan(ListVan[0])
  }, [ListVan.length])

  useEffect(() => {
    
    if (selectedVan && selectVan) selectVan(selectedVan)
  }, [selectedVan])

  useEffect(() => {
    dispatch(getVanAction())
  }, [])

  return (
    <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
      <div className='card-header border-0 pt-5'>
        <div className='van-scroll'>
          <VanNav ListVan={ListVan} selectedVan={selectedVan} onSelect={onSelectVan} />
        </div>
      </div>

      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>RECENT ACTIVITY</span>
        </h3>
      </div>
      <div className='card-body py-3' style={{position: 'relative'}}>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder text-dark text-start'>
                <th className='min-w-100px col-xxl-2 col-md-2 ms-0'>Item</th>
                <th className='min-w-100px col-xxl-1 col-md-1 ps-1'>Weight</th>
                <th className='min-w-100px col-xxl-1 col-md-1 ps-1'>Quantity</th>
                <th className='min-w-100px col-xxl-1 col-md-1 ps-1'>Pick Up time</th>
                <th className='min-w-100px col-xxl-2 col-md-2 ps-1'>Donor</th>
                <th className='min-w-100px col-xxl-1 col-md-1 ps-1'>Drop Off Time</th>
                <th className='min-w-100px col-xxl-2 col-md-2 ps-1'>Beneficiary</th>
                <th className='min-w-100px col-xxl-1 col-md-1 ps-1'>Driver</th>
                <th className='min-w-100px col-xxl-2 col-md-2 ps-1'>Van</th>
              </tr>
            </thead>
            {listDashBoard?.logs &&
              listDashBoard?.logs?.map((el: ILogs, index: number) => {
                return <DashboardTableItem data={el} />
              })}
          </table>
        </div>
      </div>
      <div style={{position: 'absolute', left: '18%', bottom: 0}}>
        <strong>Total Weight: </strong> {listDashBoard?.totalWeight}
      </div>
    </div>
  )
}

export default DashboardTable
