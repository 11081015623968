import {call, put, takeLatest} from 'redux-saga/effects'
import {alertError, alertSuccess, formatDate} from '../../../services/helper'
import {
  addNewUserAction,
  EManageSystemUserType,
  saveUserAction,
  getUserAction,
  saveSystemTypeAction,
  addNewSystemTypeAction,
  getListSystemForTypeAction,
  editSystemTypeAction,
  editUserItemAction,
  saveConfigsAction,
  getListFilterFoodTypeSuccess,
  getListFilterUserSuccess,
  getListSortSuccess,
  getListSortFoodTypeSuccess,
  getListSortUserSuccess,
} from './ManagaSystemUserAction'
import {
  apiAddNewUser,
  apiGetListUser,
  getApiSystemType,
  apiAddNewSystem,
  putApiSytemTypeItem,
  apiDeleteSystemItem,
  putApiUserItem,
  apiGetListConfigs,
  apiGetListFilter,
  apiGetListFilterFoodType,
  apiGetListFilterUser,
  apiGetListSort,
} from '@src/app/services/api/systemUserApi'
import {ISystemType} from '../models/SystemModel'
import {
  saveFoodClassAction,
  addNewFoodClassAction,
  getFoodClassAction,
  editFoodClassItemAction,
  saveFoodTypeAction,
  addNewFoodTypeAction,
  getFoodTypeAction,
  editFoodTypeItemAction,
  getListFilterSuccess,
} from './ManagaSystemUserAction'
import {IFoodClass, IFoodType, IUser} from '../models/UserModel'
import {
  apiDeleteUserItem,
  apiGetListFoodClass,
  apiAddNewFoodClass,
  putApiItemFoodClass,
  apiDeleteItemfoodClass,
  apiGetListFoodType,
  apiAddNewFoodType,
  putApiItemFoodType,
  apiDeleteItemfoodType,
} from '@src/app/services/api/systemUserApi'
import {AxiosResponse} from 'axios'
import {apiUploadFile} from '@src/app/services/api/commonApi'
import {IListResult} from '@src/app/common/CommonModel'
import {getListFilterVanSuccess, getListSortVanSuccess} from '@pages/van/redux/ManagaVanAction'
import {
  getListFilterDonorSuccess,
  getListSortDonorSuccess,
} from '@pages/donors/redux/ManagaDonorsAction'
import {
  getListFilterBNFSuccess,
  getListSortBNFSuccess,
} from '@pages/beneficiaries/redux/ManagaBeneficiariesAction'
import { IAdHoc } from '@pages/ad-hoc/models/AdHocModel'
import { IDonor } from '@pages/donors/models/DonorsModel'
import { IBeneficiaries } from '@pages/beneficiaries/models/Beneficiaries'
import { IVan } from '@pages/van/models/VanModel'

function* getListUserWatcher() {
  try {
    const res: IListResult = yield call(apiGetListUser)
    if (res) {
      // const cloneData = [...res.data]
      // const newArr: any = cloneData.map((el: IAdHoc) => {
      //   return {
      //     ...el,
      //     updatedAt: formatDate(el?.updatedAt),
      //   }
      // })
      // if(newArr) yield put(saveUserAction(newArr))
      yield put(saveUserAction(res.data))
    }
  } catch (error) {}
}

function* addNewUserWatcher({payload}: ReturnType<typeof addNewUserAction>) {
  try {
    const res: IUser = yield call(apiAddNewUser, payload.value)
    payload.onSuccess()
    alertSuccess('Save successfully !')
    yield put(getUserAction())
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* editUserItemWatcher({payload}: ReturnType<typeof editUserItemAction>) {
  try {
    const res: IUser[] = yield call(putApiUserItem, payload.value)
    alertSuccess('Successfully Edit Item !')
    yield put({type: EManageSystemUserType.EDIT_USER_RD, payload: res})
    payload.onSuccess()
    yield put(getUserAction())
  } catch (error: any) {
    alertError(error)
    payload.onError()
  }
}

function* deleteUserItemWatcher({payload}: any) {
  try {
    const res: IUser[] = yield call(apiDeleteUserItem, payload.id)
    yield put({
      type: EManageSystemUserType.DELETE_ITEM_USER_RD,
      payload: payload.id,
    })
    alertSuccess('Delete successful')
    payload.onSuccess()
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

// ================= FOOD CLASS ====================== //
function* getListFoodClassWatcher() {
  try {
    // const res: IFoodClass[] = yield call(apiGetListFoodClass)
    const res: IListResult = yield call(apiGetListFoodClass)
    if (res) {
      yield put(saveFoodClassAction(res.data))
    }
  } catch (error) {}
}

function* addNewFoodClassWatcher({payload}: ReturnType<typeof addNewFoodClassAction>) {
  try {
    const res: IFoodClass = yield call(apiAddNewFoodClass, payload.value)
    payload.onSuccess()
    alertSuccess('Save successfully !')
    yield put(getFoodClassAction())
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* editItemFoodClassWatcher({payload}: ReturnType<typeof editFoodClassItemAction>) {
  try {
    const res: IFoodClass[] = yield call(putApiItemFoodClass, payload.value)
    alertSuccess('Successfully Edit Item !')
    yield put({type: EManageSystemUserType.EDIT_ITEM_FOODCLASS_RD, payload: res})
    payload.onSuccess()
    yield put(getFoodClassAction())
  } catch (error: any) {
    alertError(error)
    payload.onError()
  }
}

function* deleteItemFoodClassWatcher({payload}: any) {
  try {
    const res: IFoodClass[] = yield call(apiDeleteItemfoodClass, payload.id)
    yield put({
      type: EManageSystemUserType.DELETE_ITEM_FOODCLASS_RD,
      payload: payload.id,
    })
    alertSuccess('Delete successful')
    payload.onSuccess()
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* getListConfigWatcher() {
  try {
    const res: {} = yield call(apiGetListConfigs)
    if (res) {
      yield put(saveConfigsAction(res))
    }
  } catch (error) {}
}

// ================= FOOD TYPE ====================== //
function* getListFoodTypeWatcher() {
  try {
    // const res: IFoodType[] = yield call(apiGetListFoodType)
    const res: IListResult = yield call(apiGetListFoodType)
    if (res) {
      yield put(saveFoodTypeAction(res.data))
    }
  } catch (error) {}
}

function* addNewFoodTypeWatcher({payload}: ReturnType<typeof addNewFoodTypeAction>) {
  try {
    if (payload.value && payload.value?.file) {
      const res_image: AxiosResponse<any> = yield call(
        apiUploadFile,
        payload.value.file,
        payload.value.iconData
      )
      payload.value.icon = res_image
    }

    yield call(apiAddNewFoodType, payload.value)
    payload.onSuccess()
    alertSuccess('Save successfully !')
    yield put(getFoodTypeAction())
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* editItemFoodTypeWatcher({payload}: ReturnType<typeof editFoodTypeItemAction>) {
  try {
    if (payload.value && payload.value?.file) {
      const res_image: AxiosResponse<any> = yield call(
        apiUploadFile,
        payload.value.file,
        payload.value.iconData
      )
      payload.value.icon = res_image
    }

    const res: IFoodType[] = yield call(putApiItemFoodType, payload.value)
    alertSuccess('Successfully Edit Item !')
    yield put({type: EManageSystemUserType.EDIT_ITEM_FOODTYPE_RD, payload: res})
    payload.onSuccess()
    yield put(getFoodTypeAction())
  } catch (error: any) {
    alertError(error ?? 'Edit Failed !')
    payload.onError()
  }
}

// ========================= SYSTEM TYPE ============================== //

function* getListSystemWatcher(type: any) {
  try {
    const res: IListResult = yield call(getApiSystemType, type.payload)
    // const res: ISystemType[] = yield call(getApiSystemType, type.payload)
    if (res) {
      yield put(saveSystemTypeAction(res.data))
    }
  } catch (error) {}
}

function* addNewSystemTypeWatcher({payload}: ReturnType<typeof addNewSystemTypeAction>) {
  try {
    const res: ISystemType = yield call(apiAddNewSystem, payload.value)
    alertSuccess('Successfully Add New !')
    yield put(getListSystemForTypeAction(res.type))
    payload.onSuccess()
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* editSystemTypeWatcher({payload}: ReturnType<typeof editSystemTypeAction>) {
  try {
    const res: ISystemType[] = yield call(putApiSytemTypeItem, payload.value)
    payload.onSuccess()
    alertSuccess('Successfully Edit Item !')
    yield put({type: EManageSystemUserType.EDIT_SYSTEM_RD, payload: res})
  } catch (error: any) {
    alertError(error)
    payload.onError()
  }
}

function* deleteSystemItemTypeWatcher({payload}: any) {
  try {
    yield call(apiDeleteSystemItem, payload.id)
    yield put({
      type: EManageSystemUserType.DELETE_ITEM,
      payload: payload.id,
    })
    alertSuccess('Delete successful')
    payload.onSuccess()
  } catch (error: any) {
    alertError(error)
    payload.onError()
  }
}

function* deleteItemFoodTypeWatcher({payload}: any) {
  try {
    yield call(apiDeleteItemfoodType, payload.id)
    yield put({
      type: EManageSystemUserType.DELETE_ITEM_FOODTYPE_RD,
      payload: payload.id,
    })
    alertSuccess('Delete successful')
    payload.onSuccess()
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}
// ==================== Search - Sort  ================= //
function* getListFilterWatcher(payload: any) {
  try {
    const res: {data: any[]; total: number} = yield call(
      apiGetListFilter,
      payload.payload.page,
      payload.payload.pagesize,
      payload.payload.filter,
      payload.payload.screen
    )
    if (res) {

      if (payload.payload.screen === 'Food-Class') yield put(getListFilterSuccess(res?.data))
      if (payload.payload.screen === 'Food-Type') yield put(getListFilterFoodTypeSuccess(res?.data))
      if (payload.payload.screen === 'User')  yield put(getListFilterUserSuccess(res))
      if (payload.payload.screen === 'Van') yield put(getListFilterVanSuccess(res?.data))
      if (payload.payload.screen === 'Donor') yield put(getListFilterDonorSuccess(res?.data))
      if (payload.payload.screen === 'Beneficiaries') yield put(getListFilterBNFSuccess(res?.data))
    }
  } catch (error) {}
}

function* getSortByWatcher(payload: any) {
  try {
    const res: {data: any[]; total: number} = yield call(
      apiGetListSort,
      payload.payload.page,
      payload.payload.pagesize,
      payload.payload.sortBy,
      payload.payload.screen,
      payload.payload.sortType
    )

    if (res) {
      if (payload.payload.screen === 'Food-Class') {
        yield put(getListSortSuccess(res))
        payload.payload.onSuccess('ASC')
      }
      if (payload.payload.onSuccess) {
        payload.payload.onSuccess()
      }
      if (payload.payload.screen === 'Food-Type') yield put(getListSortFoodTypeSuccess(res))
      if (payload.payload.screen === 'User') yield put(getListSortUserSuccess(res))
      if (payload.payload.screen === 'Van') yield put(getListSortVanSuccess(res))
      if (payload.payload.screen === 'Donor') yield put(getListSortDonorSuccess(res))
      if (payload.payload.screen === 'Beneficiaries') yield put(getListSortBNFSuccess(res))
    }
  } catch (error) {}
}

export default function* manageSystemUserSaga() {
  yield takeLatest(EManageSystemUserType.GET_LIST_USER, getListUserWatcher)
  yield takeLatest(EManageSystemUserType.ADD_NEW_USER, addNewUserWatcher)
  yield takeLatest(EManageSystemUserType.EDIT_USER_ITEM, editUserItemWatcher)
  yield takeLatest(EManageSystemUserType.GET_LIST_CONFIGS, getListConfigWatcher)

  yield takeLatest(EManageSystemUserType.GET_LIST_SYSTEM_TYPE, getListSystemWatcher)
  yield takeLatest(EManageSystemUserType.ADD_NEW_SYSTEM_TYPE, addNewSystemTypeWatcher)
  yield takeLatest(EManageSystemUserType.EDIT_SYSTEM_TYPE, editSystemTypeWatcher)
  yield takeLatest(EManageSystemUserType.DELETE_ITEM_SYSTEM, deleteSystemItemTypeWatcher)
  yield takeLatest(EManageSystemUserType.DELETE_ITEM_USER, deleteUserItemWatcher)
  yield takeLatest(EManageSystemUserType.EDIT_USER_SAGA, editUserItemWatcher)
  // yield takeLatest(EManageSystemUserType.LIST_FILTER_USER, getListFilterUserWatcher)

  yield takeLatest(EManageSystemUserType.GET_LIST_FOODCLASS, getListFoodClassWatcher)
  yield takeLatest(EManageSystemUserType.ADD_NEW_FOODCLASS, addNewFoodClassWatcher)
  yield takeLatest(EManageSystemUserType.DELETE_ITEM_FOODCLASS, deleteItemFoodClassWatcher)
  yield takeLatest(EManageSystemUserType.EDIT_FOODCLASS_ITEM, editItemFoodClassWatcher)
  yield takeLatest(EManageSystemUserType.LIST_FILTER, getListFilterWatcher)

  yield takeLatest(EManageSystemUserType.GET_LIST_FOODTYPE, getListFoodTypeWatcher)
  yield takeLatest(EManageSystemUserType.ADD_NEW_FOODTYPE, addNewFoodTypeWatcher)
  yield takeLatest(EManageSystemUserType.DELETE_ITEM_FOODTYPE, deleteItemFoodTypeWatcher)
  yield takeLatest(EManageSystemUserType.EDIT_FOODTYPE_ITEM, editItemFoodTypeWatcher)

  yield takeLatest(EManageSystemUserType.SORTBY, getSortByWatcher)
}
