import { IDonor } from "../models/DonorsModel";








export enum EManageDonorType {
  GET_LIST_DONOR = "[manage Donor] GET_LIST_DONOR",
  SAVE_DONOR_RD = "[manage Donor] SAVE_DONOR_RD",
  ADD_NEW_DONOR = "[manage Donor] ADD_NEW_DONOR",
  EDIT_DONOR = "[manage Donor] EDIT_DONOR",
  DELETE_ITEM_DONOR = "[manage Donor] DELETE_ITEM_DONOR",
  ADD_LIST_DONOR = "[manage Donor] ADD_LIST_DONOR",
  DELETE_ITEM_DONOR_RD = "[manage Donor] DELETE_ITEM_DONOR_RD",
  GET_ITEM_DONOR = "[manage Donor] GET_ITEM_DONOR",
  EDIT_ITEM_DONOR_RD = "[manage Donor] EDIT_ITEM_DONOR_RD",
  LIST_FILTER_DONOR = "[manage Donor] LIST_FILTER_DONOR",
  FILTER_SUCCESS_DONOR = "[manage Donor] FILTER_SUCCESS_DONOR",
  SORT_SUCCESS_DONOR = "[manage Donor] SORT_SUCCESS_DONOR",
  GET_LIST_ALLDONOR = "[manage Donor] GET_LIST_ALLDONOR",
  ADD_NEW_NOTE_DONOR = "ADD_NEW_NOTE_DONOR"
}

// ======================== DONOR =========================== //

export const getDonorAction = () => ({
  type: EManageDonorType.GET_LIST_DONOR,
})

export const getAllDonorScheduleAction = (payload: any) => ({
  type: EManageDonorType.GET_LIST_ALLDONOR,
  payload
})

export const saveDonorAction = (payload: IDonor[]) => ({
  type: EManageDonorType.SAVE_DONOR_RD,
  payload,
});

export const addNewDonortAction = (payload: {
  value: any,
  onSuccess: any,
  onError: any
}) => ({
  type: EManageDonorType.ADD_NEW_DONOR,
  payload,
});

export const editDonortAction = (payload: {
  value: any;
  onSuccess: any;
  onError: any;
}) => ({
  type: EManageDonorType.EDIT_DONOR,
  payload,
});

export const deleteDonortItemAction = (payload: {
  id: any;
  onSuccess: any;
  onError: any;
}) => ({
  type: EManageDonorType.DELETE_ITEM_DONOR,
  payload,
});

export const getItemDonorAction = (payload: any) => ({
  type: EManageDonorType.GET_ITEM_DONOR,
  payload,
});

export const getListFilterDonorSuccess = (payload: any) => ({
  type: EManageDonorType.FILTER_SUCCESS_DONOR,
  payload
});

export const getListSortDonorSuccess = (payload: any) => ({
  type: EManageDonorType.SORT_SUCCESS_DONOR,
  payload
});

export const addNewNoteAction = (payload: {
  value: any,
  onSuccess: any,
  onError: any
}) => ({
  type: EManageDonorType.ADD_NEW_NOTE_DONOR,
  payload,
});



