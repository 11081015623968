import React from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {useSelector} from '@src/setup/redux/RootReducer'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config

  const account = useSelector((state) => state.auth.user)

  return (
    <div
      className={clsx('menu-item mt-3 mb-0', {'here show': isActive}, 'menu-accordion')}
      data-kt-menu-trigger='click'
    >
      {title === 'SYSTEM' && account?.role === 'System Admin' ? (
        <span className='menu-link'>
          {fontIcon && <i className={clsx('bi fs-3 me-3', fontIcon)}></i>}
          <span className='menu-title'>{title}</span>
          <span className='menu-arrow'></span>
        </span>
      ) : (
        ''
      )}

      {title === 'User Name' ? (
        <span className='menu-link'>
          {fontIcon && <i className={clsx('bi fs-3 me-3', fontIcon)}></i>}
          <span className='menu-title'>
            {account?.firstName} {account?.lastName}
          </span>
          <span className='menu-arrow'></span>
        </span>
      ) : (
        ''
      )}

      <div className={clsx('menu-sub menu-sub-accordion ms-5', {'menu-active-bg': isActive})}>
        {children}
      </div>
      {/* <div style={{color: '#fff'}}>
        Log Out
      </div> */}
    </div>
  )
}

export {AsideMenuItemWithSub}
