import {call, put, takeLatest} from 'redux-saga/effects'
import { IContact } from '../models/ContactModel';
import { addNewContactAction, editContactAction, EManageContactType, getContactAction, saveContactAction } from './ManagaContactAction';
import { apiAddNewVan, apiGetListVan, putApiEditVanApi, apiDeleteItemVan } from '@src/app/services/api/vanApi'
import { apiAddNewContact, apiDeleteItemContact, apiGetListContact, putApiEditContactApi,apiDeleteDonorContact, apiDeleteDeliveryAddress,apiDeleteTargetGroup, apiDeleteItemContactBeneficiaries, apiDeleteBeneficiariesFoodItem, apiDeleteBeneficiariesRQFoodItem, apiDeleteDonorFoodItem } from '@src/app/services/api/contactApi';
import { alertError, alertSuccess } from '@src/app/services/helper';
import { IListResult } from '@src/app/common/CommonModel';


// ================= CONTACT SAGA ====================== //
function* getListContactWatcher() {
  try {
    // const res: IContact[] = yield call(apiGetListContact)
    const res: IListResult = yield call(apiGetListContact)
    if (res) {
      yield put(saveContactAction(res.data))
    }
  } catch (error) {}
}

function* addNewContactWatcher({payload}: ReturnType<typeof addNewContactAction>) {
  try {
    const res: IContact = yield call(apiAddNewContact, payload.value)
    payload.onSuccess(res)
    alertSuccess('Save successfully !')
    yield put(getContactAction())
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* editContactWatcher({payload}: ReturnType<typeof editContactAction>) {
  try {
    const res: IContact[] = yield call(putApiEditContactApi, payload.value)
    alertSuccess('Successfully Edit Item !')
    yield put({type: EManageContactType.EDIT_ITEM_CONTACT_RD, payload: res})
    payload.onSuccess()
    yield put(getContactAction())
  } catch (error: any) {
    alertError(error??'Edit Failed !')
    payload.onError()
  }
}

function* deleteContactItemWatcher({payload}: any) {
  try {
    const res: IContact[] = yield call(apiDeleteItemContact, payload.id)
    alertSuccess('Delete successful')
    payload.onSuccess()
    yield put({
      type: EManageContactType.DELETE_ITEM_CONTACT_RD,
      payload: payload.id,
    })

  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* deleteDonorContactWatcher({payload}: any) {
  try {
    const res: IContact[] = yield call(apiDeleteDonorContact, payload.id)
    payload.onSuccess()
    alertSuccess('Delete successful')
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* deleteBNFAddressWatcher({payload}: any) {
  try {
    const res: IContact[] = yield call(apiDeleteDeliveryAddress, payload.id)
    payload.onSuccess()
    alertSuccess('Delete successful')
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* deleteBNFTargetGroupWatcher({payload}: any) {
  try {
    const res: IContact[] = yield call(apiDeleteTargetGroup, payload.id)
    payload.onSuccess()
    alertSuccess('Delete successful')
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* deleteBNFContactItemWatcher({payload}: any) {
  try {
    const res: IContact[] = yield call(apiDeleteItemContactBeneficiaries, payload.id)
    payload.onSuccess()
    alertSuccess('Delete successful')
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* deleteBNFFoodItemWatcher({payload}: any) {
  try {
    const res: IContact[] = yield call(apiDeleteBeneficiariesFoodItem, payload.id)
    payload.onSuccess()
    alertSuccess('Delete successful')
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* deleteBNFRequestFoodTypeItemWatcher({payload}: any) {
  try {
    const res: IContact[] = yield call(apiDeleteBeneficiariesRQFoodItem, payload.id)
    payload.onSuccess()
    alertSuccess('Delete successful')
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* deleteDonorFoodTypeItemWatcher({payload}: any) {
  try {
    const res: IContact[] = yield call(apiDeleteDonorFoodItem, payload.id)
    payload.onSuccess()
    alertSuccess('Delete successful')
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

export default function* manageContactSaga() {
  yield takeLatest(EManageContactType.GET_LIST_CONTACT, getListContactWatcher);
  yield takeLatest(EManageContactType.ADD_NEW_CONTACT, addNewContactWatcher)
  yield takeLatest(EManageContactType.EDIT_CONTACT, editContactWatcher)
  yield takeLatest(EManageContactType.DELETE_ITEM_CONTACT, deleteContactItemWatcher)
  yield takeLatest(EManageContactType.DELETE_DONOR_CONTACT, deleteDonorContactWatcher)
  yield takeLatest(EManageContactType.DELETE_BNF_ADDRESS, deleteBNFAddressWatcher)
  yield takeLatest(EManageContactType.DELETE_BNF_GROUP, deleteBNFTargetGroupWatcher)
  yield takeLatest(EManageContactType.DELETE_BNF_CONTACT_ITEM, deleteBNFContactItemWatcher)
  yield takeLatest(EManageContactType.DELETE_BNF_FOOD_ITEM, deleteBNFFoodItemWatcher)
  yield takeLatest(EManageContactType.DELETE_BNF_RQ_FOOD_ITEM, deleteBNFRequestFoodTypeItemWatcher)
  yield takeLatest(EManageContactType.DELETE_DONOR_FOOD_ITEM, deleteDonorFoodTypeItemWatcher)
}
