import { call, takeLatest } from 'redux-saga/effects'

import { IArchivingPayload, IReportPayload } from '../models/reportModel'
import { EReportType } from './reportAction'
import { reportService, archivingService } from '../../../services/api/reportApi'
import { toast } from 'react-toastify';

function* reportsSaga({ payload, cbSuccess, cbFailure }: IReportPayload) {
    try {
        yield call(reportService, payload);
        cbSuccess?.()
    } catch (error) {
        typeof (error) === 'string' && cbFailure?.(error)
    }
}

function* archivingSaga({ cb }: IArchivingPayload) {
    try {
        yield call(archivingService);
        cb?.()
    } catch (error: any) {
        toast.error(error.message, {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    }
}


export default function* reportsWatcher() {
    yield takeLatest(EReportType.REPORTS_ACTION, reportsSaga)
    yield takeLatest(EReportType.ARCHIVING_ACTION, archivingSaga)
}