import {AxiosResponse} from 'axios'
import {call, takeLatest} from 'redux-saga/effects'

import {apiDeleteNotification, apiGetListNotifications} from '@src/app/services/api/notificationApi'
import {INotificationsResponse} from '../models/NotificationModel'
import {
  deleteNotificationAction,
  EManageNotificationsType,
  getListNotificationsAction,
} from './NotificationAction'

function* getListNotificationsWatcher({payload}: ReturnType<typeof getListNotificationsAction>) {
  try {
    const res: INotificationsResponse = yield call(
      apiGetListNotifications,
      payload.page,
      payload.size
    )
    if (res.data) {
      payload.onSuccess(res.data, res.totalPage)
    }
  } catch (e) {
    payload.onError()
    console.error(e)
  }
}

function* deleteNotificationWatcher({payload}: ReturnType<typeof deleteNotificationAction>) {
  try {
    const res: AxiosResponse<any> = yield call(apiDeleteNotification, payload.notificationId)
    if (res) payload.onSuccess()
  } catch (e) {
    payload.onError()
    console.error(e)
  }
}

export default function* notificationSaga() {
  yield takeLatest(EManageNotificationsType.GET_LIST_NOTIFICATIONS, getListNotificationsWatcher)
  yield takeLatest(EManageNotificationsType.DELETE_NOTIFICATION, deleteNotificationWatcher)
}
