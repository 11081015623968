import {call, takeLatest, put, take} from 'redux-saga/effects'
import {alertError, alertSuccess} from '@src/app/services/helper'
import {IBreadProgram, Schedule, Note} from '../models/BreadProgramModel'
import {IListResult} from '@src/app/common/CommonModel'
import {
  EBreadProgramType,
  addNewBreadBrogramAction,
  editBreadProgramAction,
  saveListBreadProgramAction,
  getListBreadProgramAction,
  deleteBreadProgramAction,
  getScheduleAction,
  getScheduleSuccesAction,
  createScheduleAction,
  deleteScheduleAction,
  getListNoteAction,
  createNoteAction,
  getListNoteActionSuccess,
  deleteNoteAction,
} from './ManagaBreadProgramActions'
import {
  apiAddBreadProgram,
  apiGetListBreadProgram,
  apiUpdateItemBreadProgram,
  apiDeleteBreadProgram,
  apiGetScheduleBeneficiary,
  apiCreateScheduleBeneficiary,
  apiDeleteSchedule,
  apiGetListNoteBreadPrograms,
  apiAddNoteBreadPrograms,
  apiDeleteNoteBreadPrograms,
} from '@src/app/services/api/breadProgramApi'
import {AxiosResponse} from 'axios'

function* addBreadProgram({payload}: ReturnType<typeof addNewBreadBrogramAction>) {
  try {
    const res: AxiosResponse<any> = yield call(apiAddBreadProgram, payload.value)
    yield put(getListBreadProgramAction())
    payload.onSuccess()
    alertSuccess('Add Bread Program successfully !')
  } catch (error: any) {
    alertError(error ?? 'Add Bread Program failed')
    payload.onError()
  }
}

function* getListBreadProgram() {
  try {
    const res: IListResult = yield call(apiGetListBreadProgram)
    if (res) {
      yield put(saveListBreadProgramAction(res.data))
    }
  } catch (error: any) {}
}

function* editBreadProgram({payload}: ReturnType<typeof editBreadProgramAction>) {
  try {
    const res: AxiosResponse<any> = yield call(apiUpdateItemBreadProgram, payload.value)
    yield put(getListBreadProgramAction())
    payload.onSuccess()
    alertSuccess('Edit Bread Program successfully !')
  } catch (error: any) {
    alertError(error ?? 'Edit Bread Program failed')
    payload.onError()
  }
}

function* deleteBreadProgramItem({payload}: ReturnType<typeof deleteBreadProgramAction>) {
  try {
    const res: AxiosResponse<any> = yield call(apiDeleteBreadProgram, payload.id)
    yield put(getListBreadProgramAction())
    payload.onSuccess()
    alertSuccess('Delete Bread Program successfully !')
  } catch (error: any) {
    alertError(error ?? 'Delete Bread Program failed')
    payload.onError()
  }
}

function* getSchedule({payload}: ReturnType<typeof getScheduleAction>) {
  try {
    const res: Schedule[] = yield call(apiGetScheduleBeneficiary, payload.beneficiaryId)
    yield put(getScheduleSuccesAction(res))
  } catch (error: any) {}
}

function* createSchedule({payload}: ReturnType<typeof createScheduleAction>) {
  try {
    const res: AxiosResponse<any> = yield call(apiCreateScheduleBeneficiary, payload.item)
    payload.onSuccess()
    alertSuccess('Add Schedule successfully !')
  } catch (error: any) {
    alertSuccess('Add Schedule failed !')
    payload.onError()
  }
}

function* deleteSchedule({payload}: ReturnType<typeof deleteScheduleAction>) {
  try {
    const res: AxiosResponse<any> = yield call(apiDeleteSchedule, payload.id)
    payload.onSuccess()
    // alertSuccess('Delete Schedule successfully !')
  } catch (error: any) {
    alertError('This item is in use and cannot be deleted !')
    payload.onError()
  }
}

function* getListNote({payload}: ReturnType<typeof getListNoteAction>) {
  try {
    const res: AxiosResponse<any> = yield call(apiGetListNoteBreadPrograms, payload.id)
    yield put(getListNoteActionSuccess(res))
  } catch (error) {}
}

function* createNote({payload}: ReturnType<typeof createNoteAction>) {
  try {
    const res: AxiosResponse<any> = yield call(apiAddNoteBreadPrograms, payload.noteItem)
    payload.onSuccess()
    alertSuccess('Create Note successfully !')
  } catch (error) {
    payload.onError()
    alertError('Create Note failed !')
  }
}

function* deleteNote({payload}: ReturnType<typeof deleteNoteAction>) {
  try {
    const res: AxiosResponse<any> = yield call(apiDeleteNoteBreadPrograms, payload.id)
    payload.onSuccess()
    alertSuccess('Delete Note successfully !')
  } catch (error) {
    payload.onError()
    alertSuccess('Delete Note failed !')
  }
}

export default function* manageBreadProgramSaga() {
  yield takeLatest(EBreadProgramType.ADD_BREAD_PROGRAM, addBreadProgram)
  yield takeLatest(EBreadProgramType.GET_LIST_BREAD_PROGRAM, getListBreadProgram)
  yield takeLatest(EBreadProgramType.EDIT_BREAD_PROGRAM, editBreadProgram)
  yield takeLatest(EBreadProgramType.DELETE_BREAD_PROGRAM, deleteBreadProgramItem)
  yield takeLatest(EBreadProgramType.GET_SCHEDULE_BENEFICIARY, getSchedule)
  yield takeLatest(EBreadProgramType.CREATE_SCHEDULE, createSchedule)
  yield takeLatest(EBreadProgramType.DELETE_SCHEDULE, deleteSchedule)
  yield takeLatest(EBreadProgramType.GET_LIST_NOTE, getListNote)
  yield takeLatest(EBreadProgramType.CREATE_NOTE, createNote)
  yield takeLatest(EBreadProgramType.DELETE_NOTE, deleteNote)
}
