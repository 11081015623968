import {IUser} from '@pages/system-user/models/UserModel'
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'

type Props = {
  mode: 'add' | 'delete' | 'edit' | 'cancel'
  data?: IUser
  onClose: Function
}

const PopUp: React.FC<Props> = ({onClose, data, mode}) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const onSaveYes = () => {
    localStorage.clear()
    history.push('/auth/login')
    document.location.reload()
  }

  return (
    <>
      <div className='card-header justify-content-center mt-5 mb-5' style={{textAlign: 'center'}}>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Do you want to sign out ? </h3>
        </div>
      </div>
      <div className='card-footer border-0 d-sm-flex justify-content-end p-sm-0 py-sm-6 px-sm-9'>
        <button className='btn btn-success p-3 mx-sm-4' onClick={() => onSaveYes()}>
          {loading ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please Wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            <>YES</>
          )}
        </button>

        <button
          className='btn btn-danger p-3 mx-sm-4'
          onClick={() => {
            onClose()
            const parentModal = document.getElementsByClassName('parent-modal')
            if (parentModal && parentModal.length) {
              parentModal[0].classList.remove('hidden')
            }
          }}
        >
          NO
        </button>
      </div>
    </>
  )
}

export default PopUp
