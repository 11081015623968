import produce from 'immer';
import { clone } from '../../../services/helper';
import { IDonor } from '../models/DonorsModel';
import { EManageDonorType } from './ManagaDonorsAction';

interface IState {
  ListDonor: IDonor[]
}

export const defaultState: IState = {
  ListDonor: [],
}

const ManagaDonorReducer = (state = defaultState, action: any) => {
  return produce(state, (draft) => {
    switch (action.type) {

      // ======== DONOR REDUCER =========== //
      case EManageDonorType.SAVE_DONOR_RD: {
        draft.ListDonor = action.payload;
        break;
      }

      case EManageDonorType.ADD_LIST_DONOR: {
        const newDonor = [
          ...[action.payload],
          ...state.ListDonor,
        ];
        draft.ListDonor = newDonor;
        break;
      }

      case EManageDonorType.EDIT_ITEM_DONOR_RD: {
        const index = state.ListDonor.findIndex((pp) => pp.id === action.payload.id)
        const newArr = clone(state.ListDonor)
        if (index !== undefined && index !== -1) {
          newArr.splice(index, 1, action.payload)
        }
        draft.ListDonor = newArr
        break
      }

      case EManageDonorType.DELETE_ITEM_DONOR_RD: {
        const newDONOR = state.ListDonor.filter(
          (pp) => pp.id !== action.payload,
        );
        draft.ListDonor = newDONOR;
        break;
      }

      case EManageDonorType.FILTER_SUCCESS_DONOR: {
        if(action.payload)
        draft.ListDonor = action.payload;
        break;
      }

      case EManageDonorType.SORT_SUCCESS_DONOR: {
        if(action.payload?.data)
        draft.ListDonor = action.payload?.data;
        break;
      }

      default:
        break
    }
  })
}
export default ManagaDonorReducer
