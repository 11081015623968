import {formatDate, formatDateUser, formatTimeAny} from '@src/app/services/helper'
import {useHistory} from 'react-router-dom'
import {IDashBoard, ILogs} from '../models/DashBoardModel'

type Props = {
  data: ILogs
}

const DashboardTableItem: React.FC<Props> = ({data}) => {
  return (
    <tr style={{padding: 0}}>
      <td>
        <div className='text-dark d-block fs-6 p-0'>{data?.foodType?.name}</div>
      </td>
      <td>
        <div className='text-dark d-block fs-6 p-0'>{Number(data?.weight).toFixed(2)}</div>
      </td>
      <td>
        <div className='text-dark d-block fs-6 p-0'>{data?.quantity}</div>
      </td>
      <td>
        <div className='text-dark d-block fs-6 p-0'>
          {data?.pickupTime ? formatTimeAny(data?.pickupTime) : ''}
        </div>
      </td>
      <td>
        <div className='text-dark d-block fs-6 p-0'>
          {(data?.donor && data?.donor?.nameEN) || data?.donor?.nameCN}
        </div>
      </td>
      <td>
        <div className='text-dark d-block fs-6 p-0'>
          {data?.dropoffTime ? formatTimeAny(data?.dropoffTime) : ''}
        </div>
      </td>
      <td>
        <div className='text-dark d-block fs-6 p-0'>
          {(data?.beneficiary && data?.beneficiary?.nameEN) || data?.beneficiary?.nameCN}
        </div>
      </td>
      <td>
        <div className='text-dark d-block fs-6 p-0'>
          {data?.driver?.firstName} {data?.driver?.lastName}
        </div>
      </td>
      <td>
        <div className='text-dark d-block fs-6 p-0'>{data?.van?.vanId}</div>
      </td>
    </tr>
  )
}

export default DashboardTableItem
