import {call, put, takeLatest} from 'redux-saga/effects'
import {alertError, alertSuccess} from '@src/app/services/helper'
import {IDonor} from '../models/DonorsModel'
import {
  addNewDonortAction,
  editDonortAction,
  EManageDonorType,
  getDonorAction,
  getListFilterDonorSuccess,
  saveDonorAction,
} from './ManagaDonorsAction'
import {
  apiGetListDonor,
  apiAddNewDonor,
  apiDeleteItemDonor,
  apiGetItemDonor,
  putApiEditDonorApi,
  apiGetListFilterDonor,
  apiGetListAllDonorSchedule,
  apiAddNoteDonor,
} from '@src/app/services/api/donorsApi'
import {AxiosResponse} from 'axios'
import {apiUploadFile} from '@src/app/services/api/commonApi'
import {IListResult} from '@src/app/common/CommonModel'

// ================= DONOR SAGA ====================== //
function* getListDonorWatcher() {
  try {
    const res: IListResult = yield call(apiGetListDonor)
    // const res: IDonor[] = yield call(apiGetListDonor)
    if (res) {
      yield put(saveDonorAction(res.data))
    }
  } catch (error) {}
}

function* getListAllDonorScheduleWatcher({payload}: any) {
  try {
    const res: IListResult = yield call(apiGetListAllDonorSchedule, payload.date)
    // const res: IDonor[] = yield call(apiGetListDonor)
    if (res) {
      yield put(saveDonorAction(res.data))
    }
  } catch (error) {}
}

function* addNewDonorWatcher({payload}: ReturnType<typeof addNewDonortAction>) {
  try {
    if (payload.value && payload.value?.fileDoc) {
      const res_image: AxiosResponse<any> = yield call(
        apiUploadFile,
        payload.value.fileDoc,
        payload.value.fileDoc.name
      )
      payload.value.disclaimer = res_image
    }

    const res: IDonor = yield call(apiAddNewDonor, payload.value)
    payload.onSuccess(res)
    alertSuccess('Save successfully !')
    // yield put(getDonorAction())
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* editDonorWatcher({payload}: ReturnType<typeof editDonortAction>) {
  try {
    if (payload.value && payload.value?.fileDoc) {
      const res_image: AxiosResponse<any> = yield call(
        apiUploadFile,
        payload.value.fileDoc,
        payload.value.fileDoc.name
      )
      payload.value.disclaimer = res_image
    }

    const res: IDonor[] = yield call(putApiEditDonorApi, payload.value)
    yield put({type: EManageDonorType.EDIT_ITEM_DONOR_RD, payload: res})
    payload.onSuccess()
    alertSuccess('Successfully Edit Item !')
    // yield put(getDonorAction())
  } catch (error: any) {
    alertError(error ?? 'Edit Failed !')
    payload.onError()
  }
}

function* deleteDonorItemWatcher({payload}: any) {
  try {
    const res: IDonor[] = yield call(apiDeleteItemDonor, payload.id)
    yield put({
      type: EManageDonorType.DELETE_ITEM_DONOR_RD,
      payload: payload.id,
    })
    payload.onSuccess()
    alertSuccess('Delete successful')
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* getItemDonorWatcher(obj: any) {
  try {
    const res: AxiosResponse<any> = yield call(apiGetItemDonor, obj.payload.id)
    obj.payload.onsuccess(res)
  } catch (error) {
    alertError(`${error}`)
  }
}

function* addNewNoteWatcher({payload}: any) {
  try {
    if (payload.value) {
      for (let i = 0; i < payload.value.content.length; i++) {
        const data = {
          id: payload.value?.id,
          content: payload.value.content[i],
          subjectId: payload.value?.subjectId,
          type: payload.value?.type,
        }
        if (data?.id === undefined) yield call(apiAddNoteDonor, data)
      }
    }
    payload.onSuccess()
    // alertSuccess('Save successfully !')
    // yield put(getDonorAction())
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

// ============ Search - Sort ============================== //

export default function* manageDonorSaga() {
  yield takeLatest(EManageDonorType.GET_LIST_DONOR, getListDonorWatcher)
  yield takeLatest(EManageDonorType.GET_LIST_ALLDONOR, getListAllDonorScheduleWatcher)
  yield takeLatest(EManageDonorType.ADD_NEW_DONOR, addNewDonorWatcher)
  yield takeLatest(EManageDonorType.EDIT_DONOR, editDonorWatcher)
  yield takeLatest(EManageDonorType.DELETE_ITEM_DONOR, deleteDonorItemWatcher)
  yield takeLatest(EManageDonorType.GET_ITEM_DONOR, getItemDonorWatcher)
  yield takeLatest(EManageDonorType.ADD_NEW_NOTE_DONOR, addNewNoteWatcher)
}
