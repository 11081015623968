import produce from 'immer';
import { clone } from '../../../services/helper';
import { IContact } from '../models/ContactModel';
import { EManageContactType } from './ManagaContactAction';

interface IState {
  ListContact: IContact[]
}

export const defaultState: IState = {
  ListContact: [],
}

const ManagaContactReducer = (state = defaultState, action: any) => {
  return produce(state, (draft) => {
    switch (action.type) {

      // ======== CONTACT REDUCER =========== //
      case EManageContactType.SAVE_CONTACT_RD: {
        draft.ListContact = action.payload;
        break;
      }

      case EManageContactType.ADD_LIST_CONTACT: {
        const newVan = [
          ...[action.payload],
          ...state.ListContact,
        ];
        draft.ListContact = newVan;
        break;
      }

      case EManageContactType.EDIT_ITEM_CONTACT_RD: {
        const index = state.ListContact.findIndex((pp) => pp.id === action.payload.id)
        const newArr = clone(state.ListContact)
        if (index !== undefined && index !== -1) {
          newArr.splice(index, 1, action.payload)
        }
        draft.ListContact = newArr
        break
      }

      case EManageContactType.DELETE_ITEM_CONTACT_RD: {
        const newContact = state.ListContact.filter(
          (pp) => pp.id !== action.payload,
        );
        draft.ListContact = newContact;
        break;
      }

      default:
        break
    }
  })
}
export default ManagaContactReducer
