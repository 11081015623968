import produce from 'immer'
import {clone} from '../../../services/helper'
import {IAdHoc} from '../models/AdHocModel'
import {EManageAdHocType} from './ManagaAdHocAction'

interface IState {
  ListAdHoc: IAdHoc[]
}

export const defaultState: IState = {
  ListAdHoc: [],
}

const ManagaAdHocReducer = (state = defaultState, action: any) => {
  return produce(state, (draft) => {
    switch (action.type) {
      // ======== VAN REDUCER =========== //
      case EManageAdHocType.SAVE_ADHOC_RD: {
        draft.ListAdHoc = action.payload
        break
      }

      case EManageAdHocType.ADD_LIST_ADHOC: {
        const newAdHoc = [...[action.payload], ...state.ListAdHoc]
        draft.ListAdHoc = newAdHoc
        break
      }

      case EManageAdHocType.EDIT_ITEM_ADHOC_RD: {
        const index = state.ListAdHoc.findIndex((pp) => pp.id === action.payload.id)
        const newArr = clone(state.ListAdHoc)
        if (index !== undefined && index !== -1) {
          newArr.splice(index, 1, action.payload)
        }
        draft.ListAdHoc = newArr
        break
      }

      case EManageAdHocType.DELETE_ITEM_ADHOC_RD: {
        const newAdHoc = state.ListAdHoc.filter(
          (pp) => pp.id !== action.payload,
        );
        draft.ListAdHoc = newAdHoc;
        break;
      }

      // case EManageAdHocType.FILTER_SUCCESS_VAN: {
      //   if(action.payload?.data)
      //   draft.ListAdHoc = action.payload?.data;
      //   break;
      // }

      // case EManageAdHocType.SORT_SUCCESS_VAN: {
      //   if(action.payload?.data)
      //   draft.ListAdHoc = action.payload?.data;
      //   break;
      // }

      default:
        break
    }
  })
}
export default ManagaAdHocReducer
