import {all, call, put, takeLatest} from 'redux-saga/effects'
import {alertError, alertSuccess, formatDate} from '../../../services/helper'
import {IListResult} from '@src/app/common/CommonModel'
import {
  addNewAdHocAction,
  editAdHocItemAction,
  EManageAdHocType,
  getAdHocAction,
  saveAdHocAction,
} from './ManagaAdHocAction'
import {
  apiAddNewAdHoc,
  apiDeleteFoodTypeItem,
  apiDeleteItemAdHoc,
  apiGetListAdHoc,
  deleteAdHocScheduleItem,
  putApiEditAdHocItemApi,
} from '@src/app/services/api/adHocApi'
import {IAdHoc, IAdHocFoodTypeItem} from '../models/AdHocModel'
import {AxiosResponse} from 'axios'
import {apiUploadFile} from '@src/app/services/api/commonApi'

// ================= AD-HOC SAGA ====================== //
function* getListAdHocWatcher() {
  try {
    // const res: IVan[] = yield call(apiGetListVan)
    const res: IListResult = yield call(apiGetListAdHoc)
    if (res) {
      yield put(saveAdHocAction(res.data))
      // const cloneData = [...res.data]
      // const newArr = cloneData.map((el: IAdHoc) => {
      //   return {
      //     ...el,
      //     pickupDate: formatDate(el?.pickupDate),
      //     adhocFoodTypes: el?.adhocFoodTypes && el?.adhocFoodTypes.map((x: IAdHocFoodTypeItem) => {
      //       return {...x, expiryDate: formatDate(x?.expiryDate)}
      //     }),
      //     source: 'Internal'
      //   }
      // })
      // if(newArr) yield put(saveAdHocAction(newArr))

    }
  } catch (error) {}
}

function* addNewAdHocWatcher({payload}: ReturnType<typeof addNewAdHocAction>) {
  try {
    if (payload.value && payload.value?.images) {
      let imagesYield: any = [];
      const imageExist = payload.value.images.filter((img: any) => !img.file).map((file: any) => file.url);
      const imageUnExist = payload.value.images.filter((img: any) => img.file);
      for (let i = 0; i < imageUnExist.length; i++) {
        imagesYield.push(
          call(apiUploadFile, imageUnExist[i].file,imageUnExist[i].file?.name || Date.now())
        )
      }
      const res_images: Array<any> = yield all(imagesYield)
      payload.value.images = [...imageExist, ...res_images]
    }
    // return

    const res: IAdHoc = yield call(apiAddNewAdHoc, payload.value)
    payload.onSuccess(res)
    alertSuccess('Save successfully !')
    // yield put(getAdHocAction())
  } catch (error: any) {
    payload.onError()
    alertError(error ?? 'Add New Failed !')
  }
}

function* editAdHocItemWatcher({payload}: ReturnType<typeof editAdHocItemAction>) {
  try {
    if (payload.value && payload.value?.images) {
      let imagesYield: any = [];
      const imageExist = payload.value.images.filter((img: any) => !img.file).map((file: any) => file.url);
      const imageUnExist = payload.value.images.filter((img: any) => img.file);
      for (let i = 0; i < imageUnExist.length; i++) {
        imagesYield.push(
          call(apiUploadFile, imageUnExist[i].file,imageUnExist[i].file.name || Date.now())
        )
      }
      const res_images: Array<any> = yield all(imagesYield)
      payload.value.images = [...imageExist, ...res_images]
    }

    const res: IAdHoc[] = yield call(putApiEditAdHocItemApi, payload.value)
    // alertSuccess('Successfully Edit Item !')
    yield put({type: EManageAdHocType.EDIT_ITEM_ADHOC_RD, payload: res})
    payload.onSuccess()
    // yield put(getAdHocAction())
  } catch (error: any) {
    alertError(error ?? 'Edit Failed !')
    payload.onError()
  }
}

function* deleteAdHocItemTypeWatcher({payload}: any) {
  try {
    // for (let i = 0; i < payload.arrId?.length; i++) {
    //   const res: IAdHoc[] = yield call(apiDeleteItemAdHoc, payload.arrId[i])
    // }

    yield all(payload.arrId.map((el: string) => { return call(apiDeleteItemAdHoc, Number(el))}))

    yield put(getAdHocAction())
    // yield put({
    //   type: EManageAdHocType.DELETE_ITEM_ADHOC_RD,
    //   payload: payload.id,
    // })
    alertSuccess('Delete successful')
    payload.onSuccess()
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* deleteFoodTypeItemTypeWatcher({payload}: any) {
  try {
    const res: any[] = yield call(apiDeleteFoodTypeItem, payload.id)
    payload.onSuccess()
    alertSuccess('Delete successful')
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

function* deleteAdHocScheduleItemTypeWatcher({payload}: any) {
  try {
    // const res: any[] = yield call(deleteAdHocScheduleItem, payload.id)
    yield all(payload?.id.map((id: any) => call(deleteAdHocScheduleItem, id)))
    // payload.onSuccess(res)
    alertSuccess('Delete successful')
  } catch (error: any) {
    payload.onError()
    alertError(error)
  }
}

// // =============== Search - Sort ======================== //
// function* getListFilterVanWatcher(payload: any) {
//   try {
//     const res: { data: IVan[]; total: number } = yield call(apiGetListFilterVan, payload.payload.page, payload.payload.pagesize, payload.payload.filter);
//     if (res) {
//       yield put(getListFilterVanSuccess(res));
//     }
//   } catch (error) { }
// }

export default function* manageAdHocSaga() {
  yield takeLatest(EManageAdHocType.GET_LIST_ADHOC, getListAdHocWatcher)
  yield takeLatest(EManageAdHocType.ADD_NEW_ADHOC, addNewAdHocWatcher)
  yield takeLatest(EManageAdHocType.EDIT_ADHOC, editAdHocItemWatcher)
  yield takeLatest(EManageAdHocType.DELETE_ITEM_ADHOC, deleteAdHocItemTypeWatcher)
  yield takeLatest(EManageAdHocType.DELETE_ADHOC_FOOD_ITEM, deleteFoodTypeItemTypeWatcher)
  yield takeLatest(EManageAdHocType.DELETE_ITEM_ADHOC_SCHEDULE, deleteAdHocScheduleItemTypeWatcher)
  // yield takeLatest(EManageVanType.LIST_FILTER_VAN,  getListFilterVanWatcher)
}
