import {IModalSchedule} from '@pages/schedule/models/ScheduleModel'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'

function ModalConfirmExit(props: any) {
  const {onDelete, onClose, confirmNavigate, index} = props
  return (
    <>
      <div className='card-header justify-content-center'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0' style={{textAlign: 'center'}}>
            You haven't saved. Do you want to exit ?
          </h3>
        </div>
      </div>
      <div className='card-footer border-0 d-sm-flex justify-content-end p-sm-0 py-sm-6 px-sm-9'>
        <div className='btn btn-success p-3 mx-sm-4' onClick={confirmNavigate}>
          YES
        </div>

        <div className='btn btn-danger p-3 mx-sm-4' onClick={onClose}>
          NO
        </div>
      </div>
    </>
  )
}

export default ModalConfirmExit
