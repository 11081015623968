import { format, addDays } from 'date-fns'
import { toast } from 'react-toastify'
import * as dotize from 'dotize'
import cnchar from 'cnchar'

import { countStroke } from '@src/libs/chinese-stroke';

export const clone = (input: any) => {
  return JSON.parse(JSON.stringify(input))
}

export const alertError = (content: string) => {
  toast.error(content, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export const alertCode401 = (content: string) => {
  toast.info(content, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export const alertSuccess = (content: string) => {
  toast.success(content, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export const alertOptimise = (content: string) => {
  toast.success(content, {
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms))

export const queryParamToObject = <T extends {}>(pathname: string) => {
  const objParam = Object.fromEntries(new URLSearchParams(pathname)) as T
  return objParam
}

export const toSlug = (str: string): string => {
  str = str.toString().toLowerCase()

  str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a')
  str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e')
  str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i')
  str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o')
  str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u')
  str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y')
  str = str.replace(/(đ)/g, 'd')

  str = str.replace(/([^0-9a-z-\s])/g, '')

  str = str.replace(/(\s+)/g, '-')

  str = str.replace(/^-+/g, '')

  str = str.replace(/-+$/g, '')
  str = str.replace(/--+/g, '-')

  return str
}

export const convertHtmlNoTag = (html: string) =>
  html
    .replace(/<[^>]+>/g, '')
    .split(' ')
    .filter((e) => !!e)
    .join('')
export const convertTimeToUTC = (date: Date) =>
  new Date(date)?.toISOString().substr(0, 19).replace('T', ' ')

export const formatDate = (date: string, formatStr?: string) => {
  if (!date) return null
  return format(new Date(date), formatStr || 'yyyy LLL dd')
}

export const formatWithTypeDate = (date: Date, formatStr?: string) =>
  format(new Date(date), formatStr || 'yyyy LLLL dd')
export const formatDateSchedule = (date: Date, formatStr?: string) =>
  format(new Date(date), formatStr || 'EEEE, do LLLL')
export const formatDateUser = (date: string, formatStr?: string) =>
  format(new Date(date), formatStr || 'dd-LL-yyyy')

export const formatTime = (date: Date, formatStr?: string) =>
  format(new Date(date), formatStr || 'HH:mm')
export const formatTimeAny = (date: any, formatStr?: string) =>
  format(new Date(date), formatStr || 'HH:mm ')

export const formatDateValidate = (date: Date, formatStr?: string) =>
  format(new Date(date), formatStr || 'yyyy-LL-dd')
export const formatDateScheduleApi = (date: Date, formatStr?: string) =>
  format(new Date(date), formatStr || 'yyyy/LL/dd')

export const formatWeekly = (date: Date, formatStr?: string) =>
  format(new Date(date), formatStr || 'E')

export const formatMonthLy = (date: Date, formatStr?: string) =>
  format(new Date(date), formatStr || 'd')

export const addTime = (date: any, extra: number) => {
  return addDays(new Date(date), extra)
}
export const guidGenerator = () => {
  return Math.floor(Math.random() * 10000000)
}

export const formatDateDashboard = (date: Date, formatStr?: string) =>
  format(new Date(date), formatStr || 'do LLLL yyyy')

export const setBlockNav = (show: boolean) => {
  if (show) {
    const kt_aside_menu = document.getElementsByClassName('kt_aside_menu')
    kt_aside_menu[0]?.classList.add('show-block-nav')
  } else {
    const kt_aside_menu = document.getElementsByClassName('kt_aside_menu')
    kt_aside_menu[0]?.classList?.remove('show-block-nav')
  }
}

export const searchArr = (arr: any, cols: any[], q: string) => {
  const result = []
  for (let i = 0; i < arr.length; i++) {
    const el = arr[i]
    const arrDotized = dotize.convert(el)
    for (let j = 0; j < cols.length; j++) {
      const col = cols[j]

      if (arrDotized[col]?.toString().toUpperCase().includes(q)) {
        result.push(el)
        break
      }
    }
  }
  console.log(result)

  return result
}

// Donor , Beneficiaries
export const sortOutput = (arr: string[], column: string, sortType: string) => {
  console.log('column:', column);

  let result: any = []
  if (arr.length > 0) {
    const chineseOutput: any = []
    const englishOutput: any = []

    arr && arr?.map((x: any) => {
      if (x?.nameEN) {
        englishOutput.push({
          ...x,
          listName: 'EN',
        })
      }
    })

    arr && arr?.map((x: any) => {
      if (x?.nameCN && !x?.nameEN) {
        chineseOutput.push({
          ...x,
          listName: 'CN',
        })
      }
    })



    if (chineseOutput) {
      chineseOutput && chineseOutput?.sort((a: any, b: any) => {
        switch (sortType) {
          case 'ASC':
            return cnchar.stroke(a[column]) > cnchar.stroke(b[column]) ? -1 : 1
          case 'DESC':
            return cnchar.stroke(a[column]) > cnchar.stroke(b[column]) ? 1 : -1
          default:
            break
        }
      })
    }

    if (englishOutput) {
      englishOutput && englishOutput?.sort((a: any, b: any) => {
        switch (sortType) {
          case 'ASC':
            return a['nameEN']?.toUpperCase() > b['nameEN']?.toUpperCase() ? -1 : 1
          case 'DESC':
            return a['nameEN']?.toUpperCase() > b['nameEN']?.toUpperCase() ? 1 : -1
          default:
            return 0
            break
        }
      })
    }
    // console.log('column', column)
    if (sortType === 'ASC') result.push(...englishOutput, ...chineseOutput)
    if (sortType === 'DESC') result.push(...chineseOutput, ...englishOutput)

    console.log('chineseOutput', chineseOutput)
    console.log('englishOutput', englishOutput)
    return result
  }
}

// Ad-Hoc , Bread-Programe
export const sortOutputDonorObj = (arr: any[], column: string, sortType: string) => {
  let result: any = []
  if (arr.length > 0) {
    const chineseOutput: any = []
    const englishOutput: any = []

    arr && arr?.map((x: any) => {
      if (x?.donor.nameEN) {
        englishOutput.push({
          ...x,
          listName: 'EN',
        })
      }
    })

    arr && arr?.map((x: any) => {
      if (x?.donor.nameCN && !x?.donor.nameEN) {
        chineseOutput.push({
          ...x,
          listName: 'CN',
        })
      }
    })



    if (chineseOutput) {
      chineseOutput && chineseOutput?.sort((a: any, b: any) => {
        switch (sortType) {
          case 'ASC':
            return cnchar.stroke(a[column]) > cnchar.stroke(b[column]) ? -1 : 1
          case 'DESC':
            return cnchar.stroke(a[column]) > cnchar.stroke(b[column]) ? 1 : -1
          default:
            break
        }
      })
    }

    if (englishOutput) {
      englishOutput && englishOutput?.sort((a: any, b: any) => {
        switch (sortType) {
          case 'ASC':
            return a?.donor['nameEN']?.toUpperCase() > b?.donor['nameEN']?.toUpperCase() ? -1 : 1
          case 'DESC':
            return a?.donor['nameEN']?.toUpperCase() > b?.donor['nameEN']?.toUpperCase() ? 1 : -1
          default:
            break
        }
      })
    }

    if (sortType === 'ASC') result.push(...englishOutput, ...chineseOutput)
    if (sortType === 'DESC') result.push(...chineseOutput, ...englishOutput)
    console.log('chineseOutput', chineseOutput)
    console.log('englishOutput', englishOutput)

    return result
  }
}

export const sortEngChStringSchedule = (arr: any[]) => {
  const cloneArr = clone(arr)
  const engArr = cloneArr.filter((x: any) => !!x.nameEN).sort((a: any, b: any) => a.nameEN.localeCompare(b.nameEN, { sensitivity: 'base' }));
  const cnArr = cloneArr.filter((x: any) => !x.nameEN).map((x: any) => ({...x, keys: getKeys(x.nameCN)})).sort(commonCompareFn);
  const result = engArr.concat(cnArr)
  return result
}

export const sortNameBeneDonor = (arr: any[], sortType: string) => {

  const cloneArr = clone(arr)
  let result = []
  switch (sortType) {
    case 'ASC':
      let engArrASC = cloneArr.filter((x: any) => !!x.nameEN).sort((a: any, b: any) => a.nameEN.toLowerCase().localeCompare(b.nameEN.toLowerCase(), { sensitivity: 'base' }));
      let cnArrASC = cloneArr.filter((x: any) => !x.nameEN).map((x: any) => ({...x, keys: getKeys(x.nameCN)})).sort(commonCompareFn);
      result = engArrASC.concat(cnArrASC)
      break;
    case 'DESC':
      let engArrDESC = cloneArr.filter((x: any) => !!x.nameEN).sort((a: any, b: any) => b.nameEN.toLowerCase() > a.nameEN.toLowerCase() ? 1 : -1);
      let cnArrDESC = cloneArr.filter((x: any) => !x.nameEN).map((x: any) => ({...x, keys: getKeys(x.nameCN)})).sort((a: any, b: any) => commonCompareFn(b, a));
      result = cnArrDESC.concat(engArrDESC)
      break;
    default:
      break;
  }
  return result;
}

export const sortDonorAddress = (arr: any[], sortType: string) => {
  const cloneArr = clone(arr)
  let result = []
  switch (sortType) {
    case 'ASC':
      let engArrASC = cloneArr.filter((x: any) => !!x.foodCollectionAddressEN).sort((a: any, b: any) => a.foodCollectionAddressEN.localeCompare(b.foodCollectionAddressEN, { sensitivity: 'base' }));
      let cnArrASC = cloneArr.filter((x: any) => !x.foodCollectionAddressEN).sort((a: any, b: any) => new Intl.Collator('zh-HK').compare(a.foodCollectionAddressCN, b.foodCollectionAddressCN));
      result = engArrASC.concat(cnArrASC)
      break;
    case 'DESC':
      let engArrDESC = cloneArr.filter((x: any) => !!x.foodCollectionAddressEN).sort((a: any, b: any) => a.foodCollectionAddressEN.localeCompare(b.foodCollectionAddressEN));
      let cnArrDESC = cloneArr.filter((x: any) => !x.foodCollectionAddressEN).sort((a: any, b: any) => new Intl.Collator('zh-HK').compare(b.foodCollectionAddressCN, a.foodCollectionAddressCN));
      result = cnArrDESC.concat(engArrDESC)
      break;
    default:
      break;
  }
  return result;
}

export const commonSortEngCn = (arr: any[], field: string, sortType: "ASC" | "DESC") => {
  const CHECK_CHINESE_REGEX = /(\p{Script=Hani})+/
  const cloneArr = clone(arr);
  if (sortType === 'ASC') {
    const engArr = cloneArr.filter((x: any) => !x[field].match(CHECK_CHINESE_REGEX))
      .sort((a: any, b: any) => a[field].toLowerCase().localeCompare(b[field].toLowerCase(), { sensitivity: 'base' }))
    const cnArr = cloneArr.filter((x: any) => x[field].match(CHECK_CHINESE_REGEX)).map((x: any) => ({ ...x, keys: getKeys(x[field]) })).sort(commonCompareFn)
    return engArr.concat(cnArr);
  } else {
    const engArr = cloneArr.filter((x: any) => !x[field].match(CHECK_CHINESE_REGEX))
      .sort((a: any, b: any) => b[field].toLowerCase().localeCompare(a[field].toLowerCase(), { sensitivity: 'base' }))
    const cnArr = cloneArr.filter((x: any) => x[field].match(CHECK_CHINESE_REGEX)).map((x: any) => ({ ...x, keys: getKeys(x[field]) })).sort((a: any, b: any) => commonCompareFn(b, a))
    return cnArr.concat(engArr);
  }
}
export const commonSortASC = (arr: any[], sortField: string) => {
  if (!arr) return []
  const cloneArr = clone(arr);
  return cloneArr.sort((a: any, b: any) => new Intl.Collator().compare(a[sortField].trim(), b[sortField].trim()))
}

export const commonSortI18nASC = (arr: any[], enField: string, cnField: string) => {
  const cloneArr = clone(arr)
  const engArr = cloneArr.filter((x: any) => !!x[enField]).sort((a: any, b: any) => a[enField].localeCompare(b[enField], { sensitivity: 'base' }));
  const cnArr = cloneArr.filter((x: any) => !x[enField]).map((x: any) => ({...x, keys: getKeys(x[cnField])})).sort(commonCompareFn);
  return engArr.concat(cnArr);
}

export const downloadFile = (fileUrl: string) => {
  window.location.href = fileUrl;
}

export const commonCompareFn = (a: any, b: any) => {
  const maxLength = Math.max(a.keys?.length, b.keys?.length);
  for (let i = 0; i < maxLength; i++) {
    if (!a.keys[i]) return -1;
    if (!b.keys[i]) return 1;
    if (a.keys[i] !== b.keys[i]) return a.keys[i] - b.keys[i];
  }
  return 0;
};

export const getKeys = (cnText: string) => cnText?.split("").map(countStroke);