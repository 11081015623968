import {IBeneficiaries} from '../models/Beneficiaries'

export enum EManageBeneficiariesType {
  GET_LIST_BNF = '[manage BNF] GET_LIST_BNF',
  SAVE_BNF_RD = '[manage BNF] SAVE_BNF_RD',
  ADD_NEW_BNF = '[manage BNF] ADD_NEW_BNF',
  EDIT_BNF = '[manage BNF] EDIT_BNF',
  DELETE_ITEM_BNF = '[manage BNF] DELETE_ITEM_BNF',
  GET_ITEM_BNF = '[manage BNF] GET_ITEM_BNF',
  ADD_LIST_BNF = '[manage BNF] ADD_LIST_BNF',
  DELETE_ITEM_BNF_RD = '[manage BNF] DELETE_ITEM_BNF_RD',
  EDIT_ITEM_BNF_RD = '[manage BNF] EDIT_ITEM_BNF_RD',
  FILTER_SUCCESS_BNF = "[manage BNF] FILTER_SUCCESS_BNF",
  SORT_SUCCESS_BNF = "[manage BNF] SORT_SUCCESS_BNF",
  GET_LIST_ALLBEN = "GET_LIST_ALLBEN"
}

// ======================== DONOR =========================== //

export const getBeneficiariesAction = () => ({
  type: EManageBeneficiariesType.GET_LIST_BNF,
})

export const getAllBeneficiariesAction = (payload: any) => ({
  type: EManageBeneficiariesType.GET_LIST_ALLBEN,
  payload
})

export const saveBeneficiariesAction = (payload: IBeneficiaries[]) => ({
  type: EManageBeneficiariesType.SAVE_BNF_RD,
  payload,
})

export const addNewBeneficiariestAction = (payload: {
  value: any
  onSuccess: any
  onError: any
}) => ({
  type: EManageBeneficiariesType.ADD_NEW_BNF,
  payload,
})

export const editBeneficiariestAction = (payload: {value: any; onSuccess: any; onError: any}) => ({
  type: EManageBeneficiariesType.EDIT_BNF,
  payload,
})

export const deleteBeneficiariestItemAction = (payload: {
  id: any
  onSuccess: any
  onError: any
}) => ({
  type: EManageBeneficiariesType.DELETE_ITEM_BNF,
  payload,
})

export const getItemBeneficiariesAction = (payload: any) => ({
  type: EManageBeneficiariesType.GET_ITEM_BNF,
  payload,
})

export const getListFilterBNFSuccess = (payload: any) => ({
  type: EManageBeneficiariesType.FILTER_SUCCESS_BNF,
  payload
});

export const getListSortBNFSuccess = (payload: any) => ({
  type: EManageBeneficiariesType.SORT_SUCCESS_BNF,
  payload
});

