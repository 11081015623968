/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG, toAbsoluteUrl} from '@metronic/helpers'
import {Dropdown1} from '@metronic/partials'
import {ISchedule} from '@pages/schedule/models/ScheduleModel'
import {
  columnSchedule,
  imageBenePickUp,
  imageDonorDropOff,
  imageDonorPickUp,
  imageBeneDropOff,
} from '@src/app/constants/constant'
import {apiGetListDashBoard} from '@src/app/services/api/dashboardApi'
import {apiGetListSchedule} from '@src/app/services/api/scheduleApi'
import {addTime, formatDateScheduleApi, formatDateValidate, sleep} from '@src/app/services/helper'
import React, {useEffect, useMemo, useState} from 'react'
import {IDashBoard, ILogs} from './models/DashBoardModel'
declare const google: any
type Props = {
  listDashBoard: IDashBoard
}

const DashboardMap: React.FC<Props> = ({listDashBoard}) => {
  const [showMap, setshowMap] = useState<any>([])

  useEffect(() => {
    if (listDashBoard) {
      const arrDonoId = listDashBoard?.logs && listDashBoard?.logs.map((d: ILogs) => d?.donorId)
      const arrBeneId =
        listDashBoard?.logs && listDashBoard?.logs.map((d: ILogs) => d?.beneficiaryId)
      const fn: any = []
      listDashBoard?.logs &&
        listDashBoard?.logs.map((x: ILogs) => {
          if (x.donor) {
            fn.push({
              id: x?.donorId,
              latitude: Number(x?.donor?.latitude),
              longitude: Number(x?.donor?.longitude),
              type: columnSchedule.allDonor,
              nameCN: x?.donor?.nameCN,
              nameEN: x?.donor?.nameEN,
              address: x?.donor?.foodCollectionAddressEN || x?.donor?.foodCollectionAddressCN,
              action: x?.action,
              icons: imageDonorDropOff,
            })
          }
        })

      listDashBoard?.logs &&
        listDashBoard?.logs.map((x: ILogs) => {
          if (x.beneficiary) {
            fn.push({
              id: x?.beneficiaryId,
              latitude: Number(x?.beneficiary?.latitude),
              longitude: Number(x?.beneficiary?.longitude),
              type: columnSchedule.allBeneficiaries,
              nameCN: x?.beneficiary?.nameCN,
              nameEN: x?.beneficiary?.nameEN,
              address: x?.beneficiary?.address,
              action: x?.action,
              icons: imageBeneDropOff,
            })
          }
        })

      // Lấy ra Donor vaf Beneficiaries cura schedule ..........
      listDashBoard?.schedules &&
        listDashBoard?.schedules.map((x: ISchedule) => {
          if (x.donor) {
            fn.push({
              id: x?.donorId,
              latitude: Number(x?.donor?.latitude),
              longitude: Number(x?.donor?.longitude),
              type: columnSchedule.donorSchedule,
              nameCN: x?.donor?.nameCN,
              nameEN: x?.donor?.nameEN,
              address: x?.donor?.foodCollectionAddressEN || x?.donor?.foodCollectionAddressCN,
              // icons: x?.donorId === dataLogs?.donorId ? imageDonorDropOff : imageDonorPickUp,
              icons:
                arrDonoId && arrDonoId.includes(x?.donorId) ? imageDonorDropOff : imageDonorPickUp,
            })
          }
        })

      listDashBoard?.schedules &&
        listDashBoard?.schedules.map((x: ISchedule) => {
          if (x.beneficiary) {
            fn.push({
              id: x?.beneficiaryId,
              latitude: Number(x?.beneficiary?.latitude),
              longitude: Number(x?.beneficiary?.longitude),
              type: columnSchedule.beneSchedule,
              nameCN: x?.beneficiary?.nameCN,
              nameEN: x?.beneficiary?.nameEN,
              address: x?.beneficiary?.address,
              icons:
                arrBeneId && arrBeneId.includes(x?.beneficiaryId)
                  ? imageBeneDropOff
                  : imageBenePickUp,
            })
          }
        })
      setshowMap(fn)
    }
  }, [listDashBoard])

  useEffect(() => {
    initMap()
  }, [showMap])

  const defaultLatLng = {lat: 22.3730829, lng: 114.1091753}
  const initMap = async () => {
    const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      center: defaultLatLng,
      zoom: 11,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
    })

    var infoWindow = new google.maps.InfoWindow()
    var lat_lng = new Array()
    var latlngbounds = new google.maps.LatLngBounds()
    let icons: any = ''
    for (let i = 0; i < showMap.length; i++) {
      var data = showMap[i]
      let markerSchedule =
        showMap[i].type === columnSchedule.donorSchedule ||
        showMap[i].type === columnSchedule.beneSchedule
          ? showMap[i]
          : []

      const contentString =
        `<div id="content">` +
        `<h4 id="firstHeading" class="firstHeading">${
          data?.nameCN !== null ? data?.nameCN : ''
        }</h4>` +
        `<h6 id="firstHeading" class="firstHeading">${
          data?.nameEN !== null ? data?.nameEN : ''
        }</h6>` +
        `<span id="firstHeading" class="firstHeading">${data?.address}</span>` +
        `</div>`

      var myLatlng = new google.maps.LatLng(data.latitude, data.longitude)
      if (markerSchedule) {
        var LatlngSchedule = new google.maps.LatLng(
          markerSchedule.latitude,
          markerSchedule.longitude
        )
        if (LatlngSchedule) {
          lat_lng.push(LatlngSchedule)
        }
      }

      var marker = new google.maps.Marker({
        position: myLatlng,
        map: map,
        icon: data?.icons,
        title: data.timestamp,
      })

      latlngbounds.extend(marker.position)
      ;(function (marker, data) {
        google.maps.event.addListener(marker, 'click', (e: any) => {
          infoWindow.setContent(contentString)
          infoWindow.open(map, marker)
        })
      })(marker, data)
    }

    if (lat_lng.length > 1) {
      map.setCenter(latlngbounds.getCenter())
      map.fitBounds(latlngbounds)
    } else {
      map.setCenter(defaultLatLng)
    }

    //Initialize the Direction Service
    var service = new google.maps.DirectionsService()

    //Loop and Draw Path Route between the Points on MAP

    for (var i = 0; i < lat_lng.length; i++) {
      if (i + 1 < lat_lng.length) {
        var src = lat_lng[i]
        var des = lat_lng[i + 1]
        // path.push(src);
        await sleep(1000)
        service.route(
          {
            origin: src,
            destination: des,
            travelMode: google.maps.DirectionsTravelMode.DRIVING,
          },
          (result: any, status: any) => {
            if (status == google.maps.DirectionsStatus.OK) {
              //Initialize the Path Array
              var path = new google.maps.MVCArray()
              //Set the Path Stroke Color
              var poly = new google.maps.Polyline({
                map: map,
                strokeColor: '#ff3333',
              })
              poly.setPath(path)
              for (var i = 0, len = result.routes[0].overview_path.length; i < len; i++) {
                path.push(result.routes[0].overview_path[i])
              }
            }
          }
        )
      }
    }
  }

  return (
    <div style={{width: 'auto', height: 500}} id='map' className={`card card-xxl-stretch mt-2`}>
      Google Map
    </div>
  )
}

export {DashboardMap}
