
import { IVan } from "../models/VanModel";

export enum EManageVanType {
  GET_LIST_VAN = "[manage van] GET_LIST_VAN",
  DELETE_ITEM_VAN = "[manage van] DELETE_ITEM_VAN",
  EDIT_VAN = "[manage van] EDIT_VAN",
  ADD_NEW_VAN = "[manage van] ADD_NEW_VAN",
  SAVE_VAN_RD = "[manage van] SAVE_VAN_RD",
  ADD_LIST_SYSTEM = "[manage van] ADD_LIST_SYSTEM",
  ADD_LIST_VAN = "[manage van] ADD_LIST_VAN",
  GET_LIST_REGION = "[manage van] GET_LIST_REGION",
  GET_LIST_CONFIGS = "[manage van] GET_LIST_CONFIGS",
  DELETE_ITEM_VAN_RD = "[manage van] DELETE_ITEM_VAN_RD",
  EDIT_ITEM_VAN_RD = "[manage van] EDIT_ITEM_VAN_RD",
  LIST_FILTER_VAN = "[manage van] LIST_FILTER_VAN",
  FILTER_SUCCESS_VAN = "[manage van] FILTER_SUCCESS_VAN",
  SORT_SUCCESS_VAN = "[manage van] SORT_SUCCESS_VAN"
}

// ======================== System Type =========================== //

export const getVanAction = () => ({
  type: EManageVanType.GET_LIST_VAN,
})

export const saveVanAction = (payload: IVan[]) => ({
  type: EManageVanType.SAVE_VAN_RD,
  payload,
});

export const addNewVanAction = (payload: {
  value: any,
  onSuccess: any,
  onError: any
}) => ({
  type: EManageVanType.ADD_NEW_VAN,
  payload,
});

export const editVanAction = (payload: {
  value: any;
  onSuccess: any;
  onError: any;
}) => ({
  type: EManageVanType.EDIT_VAN,
  payload,
});

export const deleteVanItemAction = (payload: {
  id: string;
  onSuccess: any;
  onError: any;
}) => ({
  type: EManageVanType.DELETE_ITEM_VAN,
  payload,
});

export const getListFilterVanSuccess = (payload: any) => ({
  type: EManageVanType.FILTER_SUCCESS_VAN,
  payload
});

export const getListSortVanSuccess = (payload: any) => ({
  type: EManageVanType.SORT_SUCCESS_VAN,
  payload
});


