import {useSelector} from '@src/setup/redux/RootReducer'

export interface IMenuItem {
  id: string
  title: string
  childs?: IMenuItem[]
  fontIcon?: string
  to: string
  allpermission: number
  hasBullet?: boolean
  checked?: boolean
  parentId?: string
  ckbView?: boolean
  ckbAdd?: boolean
  ckbDelete?: boolean
  ckbUpdate?: boolean
}

export interface IPermission {
  permission: string
  action: number
}

export enum PermissionAction {
  view = 1,
  add = 2,
  edit = 4,
  delete = 8,
}

const MenuList: IMenuItem[] = [
  {
    id: 'schedule',
    title: 'SCHEDULE',
    to: '/schedule',
    allpermission: 5,
    childs: [],
  },
  {
    id: 'ad-hoc',
    title: 'AD-HOC',
    to: '/ad-hoc?page=1',
    allpermission: 15,
    childs: [],
  },
  {
    id: 'van',
    title: 'VAN',
    to: '/van',
    allpermission: 15,
    hasBullet: false,
    childs: [],
  },
  {
    id: 'donors',
    title: 'DONORS',
    to: '/donors?page=1',
    allpermission: 13,
    hasBullet: false,
    childs: [],
  },
  {
    id: 'beneficiaries',
    title: 'BENEFICIARIES',
    to: '/beneficiaries?page=1',
    allpermission: 15,
    childs: [],
  },
  {
    id: 'bread-program',
    title: 'BREAD PROGRAM',
    to: '/bread-program',
    allpermission: 15,
    childs: [],
  },
  {
    id: 'pickups-delivery-history',
    title: 'PICK-UPS & DELIVERY HISTORY',
    to: '/pickups-delivery-history',
    allpermission: 9,
    childs: [],
  },
  {
    id: 'notification-email',
    title: 'NOTIFICATION & EMAIL',
    to: '/notification-email',
    allpermission: 15,
    hasBullet: false,
    childs: [],
  },
  {
    id: 'reports',
    title: 'REPORTS',
    to: '/reports',
    allpermission: 13,
    hasBullet: false,
    childs: [],
  },
  {
    id: 'contact-management',
    title: 'CONTACT MANAGEMENT',
    to: '/contact-management',
    allpermission: 15,
    childs: [],
  },

  {
    id: 'system',
    title: 'SYSTEM',
    to: '/system-user',
    fontIcon: 'bi bi-gear-fill',
    allpermission: 15,
    childs: [
      {
        id: 'user-management',
        title: 'User Management',
        to: '/system-user/user-management',
        allpermission: 5,
      },
      {
        id: 'food-type',
        title: 'Food Type',
        to: '/system-user/food-type',
        allpermission: 5,
      },
      {
        id: 'food-class',
        title: 'Food Class',
        to: '/system-user/food-class',
        allpermission: 5,
      },
      {
        id: 'restriction-type',
        title: 'Restriction Type',
        to: '/system-user/restriction-type',
        allpermission: 5,
      },
      {
        id: 'donor-categories',
        title: 'Donor Categories',
        to: '/system-user/donor-categories',
        allpermission: 5,
      },
      {
        id: 'donor-status',
        title: 'Donor Status',
        to: '/system-user/donor-status',
        allpermission: 5,
      },
      {
        id: 'beneficiary-status',
        title: 'Beneficiary Status',
        to: '/system-user/beneficiary-status',
        allpermission: 5,
      },
      {
        id: 'beneficiary-affiliation',
        title: 'Beneficiary Affiliation',
        to: '/system-user/beneficiary-affiliation',
        allpermission: 5,
      },
      {
        id: 'target-beneficiary-group',
        title: 'Target Beneficiary Group',
        to: '/system-user/target-beneficiary-group',
        allpermission: 5,
      },
      {
        id: 'corporate-group',
        title: 'Corporate Group',
        to: '/system-user/corporate-group',
        allpermission: 5,
      },
      {
        id: 'ngo-category',
        title: 'NGO Category',
        to: '/system-user/ngo-category',
        allpermission: 5,
      },
      {
        id: 'sponsor',
        title: 'Sponsor',
        to: '/system-user/sponsor',
        allpermission: 5,
      },
      {
        id: 'programme',
        title: 'Programme',
        to: '/system-user/programme',
        allpermission: 5,
      },
      {
        id: 'region',
        title: 'Region',
        to: '/system-user/region',
        allpermission: 5,
      },
      {
        id: 'contact-type',
        title: 'Contact Type',
        to: '/system-user/contact-type',
        allpermission: 5,
      },
      {
        id: 'reasons',
        title: 'Reasons',
        to: '/system-user/reasons',
        allpermission: 5,
      },
      {
        id: 'frequency',
        title: 'Frequency',
        to: '/system-user/frequency',
        allpermission: 5,
      },
    ],
  },
  {
    id: 'manage-user',
    title: 'User Name',
    to: '/manage-user',
    fontIcon: 'bi bi-person-fill',
    allpermission: 15,
    childs: [
      {
        id: 'change-password',
        title: 'CHANGE PASSWORD',
        to: '/manage-user/change-password',
        allpermission: 5,
      },
      // {
      //   id: 'sign-out',
      //   title: 'SIGN OUT',
      //   to: '/manage-user/sign-out',
      //   allpermission: 5,
      // },
    ],
  },
]
export default MenuList
