import client from '.'
import {IBreadProgram, Note, Schedule} from '@pages/bread-program/models/BreadProgramModel'

export const apiGetListBreadProgram = () => {
  return client.get(`/donor-branchs`).then((res) => res)
}

export const apiGetScheduleBeneficiary = (id: string) => {
  return client.get(`/bread-programs?beneficiaryId=${id}`).then((res) => res.data)
}

export const apiGetItemBreadProgram = (id: number) => {
  return client.get(`/donor-branchs/${id}`).then((res) => res.data)
}

export const apiUpdateItemBreadProgram = (data: IBreadProgram) => {
  return client.put(`/donor-branchs`, {...data}).then((res) => res.data)
}

export const apiAddBreadProgram = (data: IBreadProgram) => {
  return client.post(`/donor-branchs`, data).then((res) => res.data)
}

export const apiDeleteBreadProgram = (id: number) => {
  return client.delete(`/donor-branchs/${id}`).then((res) => res.data)
}

export const apiCreateScheduleBeneficiary = (data: Schedule) => {
  return client.post(`/bread-programs`, data).then((res) => res.data)
}

export const apiUpdateScheduleBeneficiary = (data: Schedule) => {
  return client.put(`/bread-programs`, data).then((res) => res.data)
}

export const apiDeleteSchedule = (id: number) => {
  return client.delete(`/bread-programs/${id}`).then((res) => res.data)
}

export const apiAddNoteBreadPrograms = (data: Note) => {
  return client.post(`/notes`, data).then((res) => res.data)
}

export const apiGetListNoteBreadPrograms = (id: string) => {
  return client.get(`/notes?subjectId=${id}&type=bread-program`).then((res) => res.data)
}

export const apiDeleteNoteBreadPrograms = (id: string) => {
  return client.delete(`/notes/${id}`).then((res) => res.data)
}
