import { ISchedule } from "../models/ScheduleModel";


export enum EManageScheduleType {
  GET_LIST_SCHEDULE = "[manage schedule] GET_LIST_SCHEDULE",
  SAVE_SCHEDULE_RD = "[manage schedule] SAVE_SCHEDULE_RD",
  ADD_NEW_SCHEDULE = "[manage schedule] ADD_NEW_SCHEDULE",
  DELETE_ITEM_SCHEDULE = "[manage schedule] DELETE_ITEM_SCHEDULE",
  DELETE_ITEM_SCHEDULE_RD = "[manage schedule] DELETE_ITEM_SCHEDULE_RD",
  RE_ASSIGNED = "[manage schedule] RE_ASSIGNED",
  ADD_NEW_NOTE_SCHEDULE = "[manage schedule] ADD_NEW_NOTE_SCHEDULE",
  GET_LIST_NOTE_SCHEDULE = "GET_LIST_NOTE_SCHEDULE",
  SAVE_SCHEDULE_NOTE = "SAVE_SCHEDULE_NOTE",
  DELETE_ITEM_SCHEDULE_Note = "DELETE_ITEM_SCHEDULE_Note",
  DELETE_ITEM_SCHEDULE_NOTE = "DELETE_ITEM_SCHEDULE_NOTE",
  OPTIMISE_SCHEDULE = "OPTIMISE_SCHEDULE"
}

export const getScheduleAction = (payload: any) => ({
  type: EManageScheduleType.GET_LIST_SCHEDULE,
  payload
})

export const saveScheduleAction = (payload: ISchedule[]) => ({
  type: EManageScheduleType.SAVE_SCHEDULE_RD,
  payload,
});

export const addNewScheduleActionSaga = (payload: {
  valueSchedule: any,
  onSuccess: any,
  onError: any
}) => ({
  type: EManageScheduleType.ADD_NEW_SCHEDULE,
  payload,
});

export const getListNoteAction = (payload: any) => ({
  type: EManageScheduleType.GET_LIST_NOTE_SCHEDULE,
  payload
})

export const saveNoteScheduleAction = (payload: any[]) => ({
  type: EManageScheduleType.SAVE_SCHEDULE_NOTE,
  payload,
});

export const addNewNoteScheduleActionSaga = (payload: {
  value: any,
  onSuccess: any,
  onError: any
}) => ({
  type: EManageScheduleType.ADD_NEW_NOTE_SCHEDULE,
  payload,
});

export const deleteScheduleItemAction = (payload: {
  id: string;
  onSuccess: any;
  onError: any;
}) => ({
  type: EManageScheduleType.DELETE_ITEM_SCHEDULE,
  payload,
});

export const reAssignedActionSaga = (payload: any) => ({
  type: EManageScheduleType.RE_ASSIGNED,
  payload
})

export const deleteNoteItemAction = (payload: {
  id: string;
  onSuccess: any;
  onError: any;
}) => ({
  type: EManageScheduleType.DELETE_ITEM_SCHEDULE_Note,
  payload,
});

export const OptimseScheduleActionSaga = (payload: any) => ({
  type: EManageScheduleType.OPTIMISE_SCHEDULE,
  payload
})



