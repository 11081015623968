import client from '.'

export const apiGetListDashBoard = (date: string) => {
  return client.get(`/logs/dashboard?date=${date}`).then(res => res.data);
}

export const apiGetListDashBoardAll = (date: string, van: string) => {
  return client.get(`/logs/dashboard?date=${date}&vanId=${van}`).then(res => res.data);
}

export const updateWeightHistory = (data: any) => {
  return client.put(`/logs`, data).then(res => res.data);
}

export const updateDiscrepenci = (data: any) => {
  return client.put(`/logs/updateDiscrepency`, data).then(res => res.data);
}




