import {IBreadProgram, Schedule, Note} from '../models/BreadProgramModel'

export enum EBreadProgramType {
  GET_LIST_BREAD_PROGRAM = '[manage BreadProgram] GET_LIST_BREAD_PROGRAM',
  SAVE_BREAD_PROGRAM_RD = '[manage BreadProgram] SAVE_BREAD_PROGRAM_RD',
  GET_ITEM_BREAD_PROGRAM = '[manage BreadProgram] GET_ITEM_BREAD_PROGRAM',
  ADD_BREAD_PROGRAM = '[manage BreadProgram] ADD_BREAD_PROGRAM',
  EDIT_BREAD_PROGRAM = '[manage BreadProgram] EDIT_BREAD_PROGRAM',
  DELETE_BREAD_PROGRAM = '[manage BreadProgram] DELETE_BREAD_PROGRAM',
  GET_SCHEDULE_BENEFICIARY = '[manage BreadProgram] GET_SCHEDULE_BENEFICIARY',
  GET_SCHEDULE_BENEFICIARY_SUCCES = '[manage BreadProgram] GET_SCHEDULE_BENEFICIARY_SUCCES',
  CREATE_SCHEDULE = '[manage BreadProgram] CREATE_SCHEDULE',
  DELETE_SCHEDULE = '[manage BreadProgram] DELETE_SCHEDULE',
  CREATE_NOTE = '[manage BreadProgram] CREATE_NOTE',
  DELETE_NOTE = '[manage BreadProgram] DELETE_NOTE',
  GET_LIST_NOTE = '[manage BreadProgram] GET_LIST_NOTE',
  GET_LIST_NOTE_SUCCESS = '[manage BreadProgram] GET_LIST_NOTE_SUCCESS',
}

// ======================== BREAD_PROGRAM =========================== //

export const getListBreadProgramAction = () => ({
  type: EBreadProgramType.GET_LIST_BREAD_PROGRAM,
})

export const saveListBreadProgramAction = (payload: IBreadProgram[]) => ({
  type: EBreadProgramType.SAVE_BREAD_PROGRAM_RD,
  payload,
})

export const getItemBreadProgramAction = (payload: any) => ({
  type: EBreadProgramType.GET_ITEM_BREAD_PROGRAM,
  payload,
})

export const addNewBreadBrogramAction = (payload: {
  value: any
  onSuccess: any
  onError: any
}) => ({
  type: EBreadProgramType.ADD_BREAD_PROGRAM,
  payload,
})

export const editBreadProgramAction = (payload: {
  value: any
  onSuccess: any
  onError: any
}) => ({
  type: EBreadProgramType.EDIT_BREAD_PROGRAM,
  payload,
})

export const deleteBreadProgramAction = (payload: {id: any; onSuccess: any; onError: any}) => ({
  type: EBreadProgramType.DELETE_BREAD_PROGRAM,
  payload,
})

export const getScheduleAction = (payload: {beneficiaryId: string}) => ({
  type: EBreadProgramType.GET_SCHEDULE_BENEFICIARY,
  payload,
})

export const getScheduleSuccesAction = (payload: Schedule[]) => ({
  type: EBreadProgramType.GET_SCHEDULE_BENEFICIARY_SUCCES,
  payload,
})

export const createScheduleAction = (payload: {item: Schedule; onSuccess: any; onError: any}) => ({
  type: EBreadProgramType.CREATE_SCHEDULE,
  payload,
})

export const deleteScheduleAction = (payload: {id: any; onSuccess: any; onError: any}) => ({
  type: EBreadProgramType.DELETE_SCHEDULE,
  payload,
})

export const getListNoteAction = (payload: {id: any}) => ({
  type: EBreadProgramType.GET_LIST_NOTE,
  payload,
})

export const getListNoteActionSuccess = (payload: any) => ({
  type: EBreadProgramType.GET_LIST_NOTE_SUCCESS,
  payload,
})

export const createNoteAction = (payload: {noteItem: any; onSuccess: any; onError: any}) => ({
  type: EBreadProgramType.CREATE_NOTE,
  payload,
})

export const deleteNoteAction = (payload: {id: any; onSuccess: any; onError: any}) => ({
  type: EBreadProgramType.DELETE_NOTE,
  payload,
})
