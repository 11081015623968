import produce from 'immer'
import {clone} from '../../../services/helper'
import {IBreadProgram, Schedule, Note} from '../models/BreadProgramModel'
import {EBreadProgramType} from './ManagaBreadProgramActions'

interface IState {
  ListBreadProgram: IBreadProgram[]
  ListSchedule: Schedule[]
  ListNote: Note[]
}

export const defaultState: IState = {
  ListBreadProgram: [],
  ListSchedule: [],
  ListNote: [],
}

const ManageBreadProgramReducer = (state = defaultState, action: any) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case EBreadProgramType.SAVE_BREAD_PROGRAM_RD:
        draft.ListBreadProgram = action.payload
        break
      case EBreadProgramType.GET_SCHEDULE_BENEFICIARY_SUCCES:
        draft.ListSchedule = action.payload
        break
      case EBreadProgramType.GET_LIST_NOTE_SUCCESS:
        draft.ListNote = action.payload
        break
      default:
        break
    }
  })
}

export default ManageBreadProgramReducer
