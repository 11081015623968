import produce from 'immer'
import {clone} from '../../../services/helper'
import {IBeneficiaries} from '../models/Beneficiaries'
import {EManageBeneficiariesType} from './ManagaBeneficiariesAction'

interface IState {
  ListBeneficiaries: IBeneficiaries[]
}

export const defaultState: IState = {
  ListBeneficiaries: [],
}

const ManagaBeneficiariesReducer = (state = defaultState, action: any) => {
  return produce(state, (draft) => {
    switch (action.type) {
      // ======== Beneficiaries REDUCER =========== //
      case EManageBeneficiariesType.SAVE_BNF_RD: {
        draft.ListBeneficiaries = action.payload
        break
      }

      case EManageBeneficiariesType.ADD_LIST_BNF: {
        const newBeneficiaries = [...[action.payload], ...state.ListBeneficiaries]
        draft.ListBeneficiaries = newBeneficiaries
        break
      }

      case EManageBeneficiariesType.EDIT_ITEM_BNF_RD: {
        const index = state.ListBeneficiaries.findIndex((pp) => pp.id === action.payload.id)
        const newArr = clone(state.ListBeneficiaries)
        if (index !== undefined && index !== -1) {
          newArr.splice(index, 1, action.payload)
        }
        draft.ListBeneficiaries = newArr
        break
      }

      case EManageBeneficiariesType.DELETE_ITEM_BNF_RD: {
        const newBeneficiaries = state.ListBeneficiaries.filter((pp) => pp.id !== action.payload)
        draft.ListBeneficiaries = newBeneficiaries
        break
      }

      case EManageBeneficiariesType.FILTER_SUCCESS_BNF: {
        if(action.payload)
        draft.ListBeneficiaries = action.payload
        break
      }

      case EManageBeneficiariesType.SORT_SUCCESS_BNF: {
        if(action.payload?.data)
        draft.ListBeneficiaries = action.payload?.data
        break
      }

      default:
        break
    }
  })
}
export default ManagaBeneficiariesReducer
